<div class="dark:text-gray-100">
  <h1 class="text-3xl mb-4 text-pink-500">{{ "title" | translate }}</h1>


  <h2 class=" text-xl mb-2 font-bold">{{ "welcome" | translate }}</h2>
  <h3 class="text-xl mb-4">{{ "welcome2" | translate }}</h3>
  <br>
  <a
    (click)="trackGit()"
    class="inline-block text-right my-4 mx-4 text-pink-500 rounded-full border border-pink-500 py-2 px-5 align-middle hover:text-pink-600 hover:border-pink-600"
    href="https://github.com/rogi-sh/defichain-income/releases"
    target='_blank'>
    Windows, Mac & Linux Releases Desktop
    <div class="inline-block w-7 h-7">
      <app-icon class="align-middle" name="github"></app-icon>
    </div>
  </a>
  <br>
  <p class="mb-2">
    {{ "opensource" | translate }}
    <br>
    <a
      (click)="trackGit()"
      href="https://github.com/Shelkovenkov/defichain-income"
      class="text-pink-500 hover:underline"
      target="_blank"
    >
      https://github.com/Shelkovenkov/defichain-income
    </a>
    <br>
    <a
      (click)="trackGit()"
      href="https://github.com/Shelkovenkov/defichain-income-server"
      class="text-pink-500 hover:underline"
      target="_blank"
    >
      https://github.com/Shelkovenkov/defichain-income-server
    </a>
  </p>
  <br>
  <a
    (click)="trackGit()"
    class="inline-block text-right mb-4 text-pink-500 rounded-full border border-pink-500 py-2 px-5 align-middle hover:text-pink-600 hover:border-pink-600"
    href="https://github.com/rogi-sh/defichain-income"
    target='_blank'>
    Open in GitHub
    <div class="inline-block w-7 h-7">
      <app-icon class="align-middle" name="github"></app-icon>
    </div>
  </a>
  <br>
  <h3 class="mb-2 text-xl">
    Backend Version {{version}}
  </h3>
  <br>
  <h3 class="mb-2 text-2xl">
    {{ "powered" | translate }}

  </h3>

  <p class="mb-2">
    Cake API
    <a class="text-pink-500 hover:underline" href='https://api.cakedefi.com/coins/apy'>
      https://api.cakedefi.com/coins/apy
    </a>
  </p>

  <p class="mb-2">
    Ocean API
    <a class="text-pink-500 hover:underline"
       target="_blank"
       href="https://ocean.defichain.com">
      https://ocean.defichain.com
    </a>
  </p>

  <p class="mb-2">
    Masternode Monitor
    <a class="text-pink-500 hover:underline"
       target="_blank"
       href="https://www.defichain-masternode-monitor.com/">
      defichain-masternode-monitor.com/
    </a>
  </p>

  <p class="mb-2">
    {{ "history" | translate }}
    <a
      (click)="trackGraphQL()"
      href="https://aws-backend.defichain-income.com/graphql"
      target="_blank"
      class="text-pink-500 hover:underline"
    >
      GraphQL Studio
    </a>
  </p>
  <p class="mb-2">
    {{ "currency-info" | translate }}
    <a
      href="https://github.com/fawazahmed0/currency-api"
      target="_blank"
      class="text-pink-500 hover:underline"
    >
      https://github.com/fawazahmed0/currency-api
    </a>
  </p>
  <p class="mb-2">
    {{ "portfolio" | translate }}
    <a
      href="https://github.com/DeFi-PortfolioManagement"
      target="_blank"
      class="text-pink-500 hover:underline"
    >
      https://github.com/DeFi-PortfolioManagement
    </a>
  </p>

  <p class="mb-2">
    Pool {{ 'correlation' | translate }} {{ 'since' | translate }} 21.3.2021
    <a
      href="https://en.wikipedia.org/wiki/Pearson_correlation_coefficient"
      target="_blank"
      class="text-pink-500 hover:underline"
    >
      Pearson correlation coefficient
    </a>
  </p>


  <br>
  <p class="mb-2">
    Telegram
    <a href="https://t.me/defichainincome"
       target="_blank"
       class="text-pink-500 hover:underline"
    >
      https://t.me/defichainincome
    </a>
  </p>
  <br>
  <p class="mb-2">
    Youtube Tutorial English
    <br>
    26.4.2021
    <a href="https://youtu.be/BOxoWVkwJzU" target="_blank" class="text-pink-500 hover:underline">
      https://youtu.be/BOxoWVkwJzU
    </a>
    <br>
    30.3.2022
    <a href="https://youtu.be/q9Wtlj_2ypg" target="_blank" class="text-pink-500 hover:underline">
      https://youtu.be/q9Wtlj_2ypg
    </a>
  </p>

  <p class="mb-2">
    Youtube Tutorial German
    <br>
    26.4.2021
    <a href="https://youtu.be/n3frUUXJ_RU" target="_blank" class="text-pink-500 hover:underline">
      https://youtu.be/n3frUUXJ_RU
    </a>
    <br>
    23.3.2022
    <a href="https://youtu.be/yuh6QVM1TVA" target="_blank" class="text-pink-500 hover:underline">
      https://youtu.be/yuh6QVM1TVA
    </a>
  </p>

  <br>
  <br>
  <br>
  <div class="mt-5 ">
    <span class='text-xl'>{{ 'support' | translate }} DFI (DefiChain token):</span>
    <br>
    <br>
    <span class="font-semibold mb-2 text-xl">
      dZ5gsU6AHnDhTYHXh5nJvoq3yrrkve1WAi
    </span>
    <br>
    <br>
    <img src='/assets/img/Tips.png' alt='Tips address' width='200' height='240'>
    <br><br>
    <div>
      Igor Shelkovenkov<br/>
      <a class="text-pink-500 hover:underline block" href="https://www.topiet.com">TopieT GmbH</a>
      Giesenheide 40<br/>
      40724 Hilden
    </div>
    <div class="text-base">
      <a class="text-pink-500 hover:underline block" href="mailto:crypto@shelkovenkov.de?subject=Contact DEFI Income">
        crypto@shelkovenkov.de
      </a>
      <a class="text-pink-500 hover:underline block" href="https://t.me/ishelkovenkov">https://t.me/ishelkovenkov</a>
    </div>
  </div>
</div>
