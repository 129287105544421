<div class="flex flex-col items-stretch min-h-96vh sm:min-h-full h-96">
  <select
    class='bg-gray-100 px-3 py-2 rounded-md w-40 dark:bg-gray-700 dark:text-gray-100'
    (change)='onChangeCPCurrency($event.target.value)'
    value='coinpaprikaCurrency'
  >
    <option [selected]="coinpaprikaCurrency === 'BTC'" value='BTC'>DFI/BTC</option>
    <option [selected]="coinpaprikaCurrency === 'BCH'" value='BCH'>DFI/BCH</option>
    <option [selected]="coinpaprikaCurrency === 'ETH'" value='ETH'>DFI/ETH</option>
    <option [selected]="coinpaprikaCurrency === 'LTC'" value='LTC'>DFI/LTC</option>
    <option [selected]="coinpaprikaCurrency === 'DOGE'" value='DOGE'>DFI/DOGE</option>
    <option [selected]="coinpaprikaCurrency === 'USDT'" value='USDT'>DFI/USDT</option>
    <option [selected]="coinpaprikaCurrency === 'USD'" value='USD'>DFI/USD</option>
    <option [selected]="coinpaprikaCurrency === 'EUR'" value='EUR'>DFI/EUR</option>
    <option [selected]="coinpaprikaCurrency === 'CHF'" value='CHF'>DFI/CHF</option>
    <option [selected]="coinpaprikaCurrency === 'GBP'" value='GBP'>DFI/GBP</option>

    <option [selected]="coinpaprikaCurrency === 'Bitcoin'" value='Bitcoin'>BTC</option>
    <option [selected]="coinpaprikaCurrency === 'Ethereum'" value='Ethereum'>ETH</option>
    <option [selected]="coinpaprikaCurrency === 'Litecoin'" value='Litecoin'>LTC</option>
    <option [selected]="coinpaprikaCurrency === 'BitcoinCash'" value='BitcoinCash'>BCH</option>
    <option [selected]="coinpaprikaCurrency === 'Doge'" value='Doge'>DOGE</option>
    <option [selected]="coinpaprikaCurrency === 'Polygon'" value='Polygon'>MATIC</option>
    <option [selected]="coinpaprikaCurrency === 'Polkadot'" value='Polkadot'>DOT</option>
    <option [selected]="coinpaprikaCurrency === 'Solana'" value='Solana'>SOL</option>
    <option [selected]="coinpaprikaCurrency === 'Sui'" value='Sui'>SUI</option>
    <option [selected]="coinpaprikaCurrency === 'CryptoFranc'" value='CryptoFranc'>XCHF</option>

    <option *ngFor='let stock of getToken()'
            [selected]="coinpaprikaCurrency === stock.tokenASymbol"
            [value]="stock.tokenASymbol">
            {{stock.tokenASymbol}}
    </option>
  </select>


  <div class="tradingview-widget-container mt-2 flex-1" style="max-height: 85%;">
    <div id="tradingview_59a11"></div>
  </div>
</div>
