<div>
  <h1 [ngClass]="{'text-3xl text-pink-500': !showOnlyGraph, 'text-lg dark:text-gray-100': showOnlyGraph}">
    {{ "income.title" | translate }}
  </h1>
  <h2 class="text-lg mt-2 mb-4 dark:text-gray-100">
    {{ "income.distribution" | translate }}
  </h2>

  <div
    class="grid grid-flow-row grid-cols-1 auto-rows-max gap-4 lg:gap-2"
    [ngClass]="{'lg:grid-cols-2': !showOnlyGraph}"
  >
    <div class="order-2 lg:order-1" *ngIf="chartOptions2 && chartOptions2.series.length > 0">
      <apx-chart
        [series]="chartOptions2?.series"
        [chart]="chartOptions2?.chart"
        [labels]="chartOptions2?.labels"
        [colors]="chartOptions2?.colors"
        [fill]="chartOptions2?.fill"
        [stroke]="chartOptions2?.stroke"
        [responsive]="chartOptions2?.responsive"
        [theme]="{ mode: getTheme() }"
      ></apx-chart>
    </div>

    <div class="order-1 lg:order-2">
      <div class="grid grid-flow-col select-none mb-4"
           [ngClass]="dfiInMasternodes > 0 ? 'grid-cols-4 ':  'grid-cols-3'">
        <a
          (click)="handleTab('All')"
          class="flex items-center justify-center text-center cursor-pointer border-b-2 border-transparent uppercase pb-2"
          [ngClass]="selectedTab === 'All' ? 'text-pink-500 border-pink-500':  'dark:text-gray-100'"
        >
          {{ 'income.all' | translate }}
        </a>
        <a *ngIf="dfiInStaking > 0 || dfiInLockStaking > 0"
           (click)="handleTab('Staking')"
          class="flex items-center justify-center text-center cursor-pointer border-b-2 border-transparent uppercase pb-2"
          [ngClass]="selectedTab === 'Staking' ? 'text-pink-500 border-pink-500':  'dark:text-gray-100'"
        >
          Staking
        </a>
        <a
          (click)="handleTab('LM')"
          class="flex items-center justify-center text-center cursor-pointer border-b-2 border-transparent uppercase pb-2"
          [ngClass]="selectedTab === 'LM' ? 'text-pink-500 border-pink-500':  'dark:text-gray-100'"
        >
          <span class="sm:hidden">LM</span>
          <span class="hidden sm:block">Liquidity Mining</span>
        </a>
        <a *ngIf="dfiInMasternodes > 0"
          (click)="handleTab('MN')"
          class="flex items-center justify-center text-center cursor-pointer border-b-2 border-transparent uppercase pb-2 overflow-hidden overflow-ellipsis flow-root"
          [ngClass]="selectedTab === 'MN' ? 'text-pink-500 border-pink-500':  'dark:text-gray-100'"
        >
          Masternode
        </a>
      </div>

      <div class="rounded p-2 bg-gray-100 dark:bg-gray-600" *ngIf="selectedTab === 'All'">

        <h3 class="text-lg font-semibold mt-4 mb-1 dark:text-gray-100">
          {{ "income.share" | translate }}
        </h3>

        <div class="grid grid-flow-col grid-cols-2 md:gap-2">
          <span
            class="grid grid-flow-row auto-rows-max  grid-cols-2 md:grid-cols-3 lg:grid-cols-3 lg:gap-4 gap-6 border-b pb-2 col-span-2 mb-2"
          >
            <span class="text-xl dark:text-gray-100">
              <span class="text-pink-500 font-semibold">
                {{getAnteilStakingOfIncome() | number: "1.1-1"}} %
              </span>
              Staking
            </span>
            <span class="text-xl dark:text-gray-100">
              <span class="text-pink-500 font-semibold">
                {{getAnteilLMOfIncome() | number: "1.1-1"}} %
              </span>
              LM
            </span>
            <span *ngIf="dfiInMasternodes" class="text-xl dark:text-gray-100">
              <span class="text-pink-500 font-semibold">
                {{getAnteilMasternodeOfIncome() | number: "1.1-1"}} %
              </span>
              Masternode
            </span>
          </span>
        </div>

        <app-earning
          [isIncognitoModeOn]="isIncognitoModeOn"
          [out]="poolAllOut"
          [pool]="poolBtc"
          [fiat]="fiat"
        ></app-earning>
      </div>

      <div class="rounded p-2 bg-gray-100 dark:bg-gray-600" *ngIf="selectedTab === 'Staking'">
        <div *ngIf="dfiInStaking === 0 && dfiInLockStaking === 0" class="dark:text-gray-100">
          {{ "noStakingAvailable" | translate }}
        </div>
        <div *ngIf="dfiInStaking > 0 || dfiInLockStaking > 0">

          <h3 class="text-lg font-semibold mt-4 mb-1 dark:text-gray-100">
            {{ "balance" | translate }} DFI
          </h3>

          <div class="grid grid-flow-col grid-cols-2 md:gap-2">
          <span
            class="grid grid-flow-row auto-rows-max grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-6 border-b pb-2 col-span-2"
          >
            <span class="text-l dark:text-gray-100">
              <span class="text-pink-500 font-semibold">
                <span *ngIf="dfiInStaking > 0">{{isIncognitoModeOn ? '****' : dfiInStaking | number: "1.2-2"}} Cake <br></span>
                <span *ngIf="dfiInLockStaking > 0">{{isIncognitoModeOn ? '****' : dfiInLockStaking  | number: "1.2-2"}} Lock</span>
              </span>
            </span>
            <span class="text-l dark:text-gray-100">
               <span class="text-pink-500 font-semibold">
                 <span *ngIf="dfiInStaking > 0"> {{stakingApy | number: "1.1-1"}} % Apy Cake <br></span>
                 <span *ngIf="dfiInLockStaking > 0">{{stakingLockApy * 100| number: "1.1-1"}} % Apy Lock <br></span>
              </span>
            </span>
          </span>
          </div>

          <h3 class="my-2 grid grid-flow-row grid-cols-3 lg:grid-cols-4 auto-rows-max auto-cols-fr gap-2 dark:text-gray-100">
            <div>
              {{ "balance" | translate}} Total
            </div>
            <div>

              <span *ngIf="!isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              <app-price [fiat]="fiat" [usdPrice]="getDfiCountStakingUsd()"></app-price>
            </span>
              <span *ngIf="isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              *****
            </span>
            </div>
            <div *ngIf="dfiInStaking > 0">
              {{ "balance" | translate}} Cake
            </div>
            <div *ngIf="dfiInStaking > 0">
              <span *ngIf="!isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              <app-price [fiat]="fiat" [usdPrice]="dfiInStaking * poolBtc?.priceB"></app-price>
            </span>
              <span *ngIf="isIncognitoModeOn && dfiInStaking > 0" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              *****
            </span>
            </div>

            <div *ngIf="dfiInLockStaking > 0">
              {{ "balance" | translate}} Lock
            </div>
            <div *ngIf="dfiInLockStaking > 0">
              <span *ngIf="!isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              <app-price [fiat]="fiat" [usdPrice]="dfiInLockStaking * poolBtc?.priceB"></app-price>
            </span>
              <span *ngIf="isIncognitoModeOn && dfiInLockStaking > 0" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              *****
            </span>
            </div>

          </h3>
          <br>
          <div class="mt-2">
            <app-earning
              [isIncognitoModeOn]="isIncognitoModeOn"
              [out]="stakingOut"
              [pool]="poolBtc"
              [fiat]="fiat"
            >
            </app-earning>
          </div>
        </div>
      </div>

      <div class="rounded p-2 bg-gray-100 dark:bg-gray-600" *ngIf="selectedTab === 'LM'">
        <h3 class="text-lg font-semibold mt-4 mb-1 dark:text-gray-100">
          {{ "income.share" | translate }}
        </h3>

        <div class='grid grid-flow-col grid-cols-2 md:gap-2'>
          <span
            class='grid grid-flow-row auto-rows-max grid-cols-2 md:grid-cols-3 lg:gap-4 gap-6 border-b pb-2 col-span-2'
          >
          <div *ngFor='let l of getPoolsIncome()'>
            <span class='text-xl dark:text-gray-100'>
              <span class='text-pink-500 font-semibold'>
                {{getAnteilPoolAnGesamtLM(l) | number: "1.1-1"}} %
              </span>
              {{l.name}}
            </span>
          </div>

          </span>
        </div>

        <h3 class="text-lg font-semibold mt-4 mb-1 dark:text-gray-100">
          {{ "balance" | translate }}
        </h3>

        <h3 class="my-2 grid grid-flow-row grid-cols-3 lg:grid-cols-4 auto-rows-max auto-cols-fr gap-2 dark:text-gray-100">
          <span>
            {{ "balance" | translate}} in Fiat
          </span>
          <div>
            <span *ngIf="!isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              <app-price [fiat]="fiat" [usdPrice]="getLMUsd"></app-price>
            </span>
            <span *ngIf="isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              *****
            </span>
          </div>
        </h3>
        <br>
        <app-earning
          [isIncognitoModeOn]="isIncognitoModeOn"
          [out]="lmOut"
          [pool]="poolBtc"
          [fiat]="fiat"
        ></app-earning>
      </div>

      <div class="rounded p-2 bg-gray-100 dark:bg-gray-600" *ngIf="selectedTab === 'MN'">
        <div *ngIf="dfiInMasternodes < 1">
          {{ "noMasternodeAvailable" | translate }}
        </div>
        <div *ngIf="dfiInMasternodes > 0">

          <h3 class="text-lg font-semibold mt-4 mb-1 dark:text-gray-100">
            {{ "balance" | translate }}
          </h3>

          <div class="grid grid-flow-col md:gap-2">
          <span
            class="grid grid-flow-row auto-rows-max grid-cols-3 lg:gap-2 gap-6 border-b pb-2">
            <span class="text-xl dark:text-gray-100">
              <span class="text-pink-500 font-semibold">
                {{isIncognitoModeOn ? '****' : dfiInMasternodes | number: "1.2-2"}}
              </span>
              Total
            </span>
            <span class="text-xl dark:text-gray-100">
               <span class="text-pink-500 font-semibold">
                {{masternodesApr | number: "1.1-1"}}
              </span>
              % APR
            </span>
          </span>
          </div>

          <h3 class="my-2 grid grid-flow-row grid-cols-3 lg:grid-cols-4 auto-rows-max auto-cols-fr gap-2 dark:text-gray-100">
            <div>
              {{ "balance" | translate}} in Fiat
            </div>
            <div>
              <span *ngIf="!isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              <app-price [fiat]="fiat" [usdPrice]="getDfiCountMasternodesUsd()"></app-price>
            </span>
              <span *ngIf="isIncognitoModeOn" class="rounded-full bg-gray-800 hover:bg-pink-500 text-white text-sm px-2 py-1 transition-all cursor-pointer">
              *****
            </span>
            </div>
          </h3>
          <br>
          <div class="mt-2">
            <app-earning
              [isIncognitoModeOn]="isIncognitoModeOn"
              [out]="masternodeOut"
              [pool]="poolBtc"
              [fiat]="fiat"
            >
            </app-earning>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
