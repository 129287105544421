<div class='dark:text-gray-100'>
  <h1 class='text-3xl mb-4 text-pink-500'>{{ "newsletter.title" | translate }}</h1>
  <div class='rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white p-2 max-h-120 overflow-auto'>
    <div class='inline-lg-block rounded bg-gray-100 dark:bg-gray-600 dark:text-white p-2'>
      <h3 class='text-xl mb-4'>{{ "newsletter.title-2" | translate }}</h3>
    </div>
  </div>
  <br><br>
  <div class='rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white p-2 max-h-96 overflow-auto'>
    <div class='inline-lg-block rounded bg-gray-100 dark:bg-gray-600 dark:text-white p-2'>
      <h2 class='text-xl '>{{ "newsletter.title-3" | translate }}</h2>
      <h2 class='text-xl mb-4 '>{{ "newsletter.title-4" | translate }}</h2>
      <h2 class='text-xl mb-4 '>{{ "newsletter.title-5" | translate }}</h2>
    </div>
  </div>
  <br><br>
  <div class='rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white p-2 max-h-120 overflow-auto'>
    <div class='inline-lg-block rounded bg-gray-100 dark:bg-gray-600 dark:text-white p-2'>
      <h2 class='text-xl '>Status - V1 &#9989;</h2>
      <p class='mb-4 '>Delivery every day 17:00 UTC &#9989;</p>
        <p class=' mb-4 '>Account Information &#9989;</p>
        <p class=' mb-4 '>Wallet Balances &#9989;</p>
        <p class=' mb-4 '>Pools Balances Crypto & Stocks &#9989;</p>
        <p class='mb-4 '>Vaults Links &#9989;</p>

      <h3 class='text-xl '>Next - V2 - 02.2022 &#9989;</h3>
      <p class='mb-4 '>Defichain Statistics from defichain-income.com &#8987;</p>
      <h3 class='text-xl '>V3 - 03.2022</h3>
      <p class=' mb-4 '> Integration of external sources and data &#8987;</p>
      <h3 class='text-xl '>V4 - 04.2022</h3>
      <p class='mb-4 '> Customize your Newsletter directly on the website &#8987;</p>

    </div>
  </div>

  <br><br>

  <div class='rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white p-2 max-h-96 overflow-auto'>
    <div class='inline-lg-block rounded bg-gray-100 dark:bg-gray-600 dark:text-white p-2'>
      <h3 class='mb-4 '>{{ "newsletter.address" | translate }}</h3>

      <div
        class='rounded-full bg-pink-500 hover:bg-pink-600 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block m-1 hide-content ng-star-inserted min-h-5'>
        df1qdc79xa70as0a5d0pdtgdww7tu65c2ncu9v7k2k
      </div>
      <br><br>
      <img src='/assets/img/IMG_1532.jpg' alt='Target address newsletter' width='200' height='240'>
    </div>
  </div>

  <br><br>
  <div class='rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white p-2 max-h-120 overflow-auto'>
    <div class='inline-lg-block rounded bg-gray-100 dark:bg-gray-600 dark:text-white p-2'>
      <h3 class='mb-4 '>{{ "newsletter.status" | translate }}
        <div *ngIf="newsletter && newsletter?.status === 'payed'"
             class='rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block m-1 hide-content ng-star-inserted min-h-5'>
          Subscribed & Payed
        </div>
        <div *ngIf='!newsletter || !newsletter.email'
             class='rounded-full bg-red-600 hover:bg-red-600 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block m-1 hide-content ng-star-inserted min-h-5'>
          Not Subscribed
        </div>
        <div
          *ngIf="newsletter && (newsletter?.status === 'subscribed' || newsletter?.status === 'subscribedWithAddress' || newsletter?.status === 'subscribedWithoutAddress')"
          class='rounded-full text-black bg-yellow-500 hover:bg-yellow-600 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block m-1 hide-content ng-star-inserted min-h-5'>
          Subscribed & Not Payed
        </div>
      </h3>

      <div class='inline-block w-full lg:w-2/4 md:w-3/4 my-2 md:mt-0'>
        <div
          class='relative h-14 input-component'
          [ngClass]="!newsletter ? 'empty' : ''"
          title='Paying Address'
        >
          {{ "newsletter.your-address" | translate }}
          <br>
          <br>
          <input
            required
            [(ngModel)]='address'
            id='inputAddress'
            class='h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none'
          />
        </div>
      </div>
      <br>
      <div class='inline-block w-full lg:w-2/4 md:w-3/4 my-2 md:mt-0'>
        <div
          class='relative h-14 input-component'
          [ngClass]="!newsletter ? 'empty' : ''"
          title='E-Mail'
        >
          <br>
          E-Mail
          <br>
          <br>
          <input
            required
            [(ngModel)]='email'
            id='inputMail'
            class='h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none'
          />
        </div>
      </div>
      <br>
      <br>
      <div *ngIf='errorBackend' class='bg-red-600 text-white w-full py-2 px-4 rounded-sm my-2'
           role='alert'>
        {{errorBackend}}
      </div>
      <div
        *ngIf='successBackend'
        class='bg-green-500 text-white w-full py-2 px-4 rounded-sm my-2'
        role='alert'
      >
        {{successBackend}}
      </div>

      <br>
      <br>
      <br>
      <div *ngIf='!key' class='bg-red-600 text-white w-full lg:w-2/4 md:w-3/4 py-2 px-4 rounded-sm my-2'
           role='alert'>
        {{ "newsletter.button" | translate }}
      </div>

      <div *ngIf='key' class='w-full lg:w-2/4 md:w-3/4 md:pl-2 align-top'>
        <button
          class='bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 rounded-md w-full h-14 text-white focus:outline-none uppercase'
          (click)='save()'>
          {{ "newsletter.save" | translate }}
        </button>
      </div>
      <br>
      <br>
      <div *ngIf='key' class='w-full lg:w-1/4 md:w-2/4 md:pl-2 align-top'>
        <button
          class='bg-red-500 hover:bg-red-600 transform transition-all delay-150 rounded-md w-full h-14 text-white focus:outline-none uppercase'
          (click)='unsubscribe()'>
          Unsubscribe
        </button>
      </div>

    </div>
  </div>
</div>
