<div class="bg-gray-100 rounded-md p-2 dark:bg-gray-600 dark:text-gray-100">
  <h2>
                    <span class="text-lg font-semibold mb-1">
                      {{getSymbolA(pool)}}/{{getSymbolB(pool)}} -
                      {{isIncognitoModeOn ? '****' : getAprTotal(pool)| number: '1.2-2' }}% Total
                    <span class="text-xs"> ({{isIncognitoModeOn ? '****' : getAprRewards(pool)| number: '1.1-1' }}% Reward
                      / {{isIncognitoModeOn ? '****' : getAprFees(pool)| number: '1.3-3' }}% Fees)</span>
                    </span>
  </h2>
  <br>
  <h3>
    Pool {{ 'share' | translate }}
    <span class="rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-base px-4 py-1 cursor-pointer my-2 m-1">
                      {{isIncognitoModeOn ? '****' : anteilAmPool * 100| number: '1.4-4' }} %
                    </span>
    <div
      class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-base px-4 py-1 cursor-pointer my-2 m-1 inline-block"
    >
      <div>
        {{isIncognitoModeOn ? '****' : getPoolPairFromShare(anteilAmPool, pool)?.dfiOrUsdToken | number: '1.2-2'}} {{getSymbolB(pool)}}
        / {{isIncognitoModeOn ? '****' : getPoolPairFromShare(anteilAmPool, pool)?.poolPairToken | number: '1.4-4'}} {{getSymbolA(pool)}}
      </div>
    </div>
  </h3>
  <br>
  <app-earning
    [isIncognitoModeOn]="isIncognitoModeOn"
    [out]="poolOut"
    [pool]="poolBtc"
    [fiat]="fiat"
  ></app-earning>
</div>
