<div class="grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 select-none mt-4 dark:text-gray-100">
  <div class="pr-4 lg:border-r-2">
    <p class="mb-4">{{ 'income.calcText' | translate }}</p>
    <div class="flex w-full flex-wrap">
      <div
        class="relative h-14 input-component w-full sm:w-1/2 sm:pr-2 mb-2 sm:mb-0"
        [ngClass]="!stakingCalcOut.dfiAmount ? 'empty' : ''"
        title="DFI per {{ 'Min' | translate }}"
      >
        <label for="stakingCalcOur" class="absolute left-2 transition-all px-1 pointer-events-none">
          DFI
        </label>
        <input
          class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          required
          [(ngModel)]="stakingCalcOut.dfiAmount"
          (ngModelChange)="calcStakingOutCome()"
          type="number"
          id="stakingCalcOur"
        >
      </div>

      <div
        class="relative h-14 input-component w-full sm:w-1/2 sm:pr-2"
        [ngClass]="!stakingCalcOut.apy ? 'empty' : ''"
        title="DFI per {{ 'Min' | translate }}"
      >
        <label for="stakingCalcOutApy" class="absolute left-2 transition-all px-1 pointer-events-none">
          APY
        </label>
        <input
          class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          required
          [(ngModel)]="stakingCalcOut.apy"
          (ngModelChange)="calcStakingOutCome()"
          type="number"
          id="stakingCalcOutApy"
        >
      </div>
    </div>
    <div class="mt-4">
      <app-earning
        [isIncognitoModeOn]=false
        [out]="stakingCalcOut"
        [pool]="poolBtc"
        [fiat]="fiat"
      ></app-earning>
    </div>
  </div>

  <div class="pt-4 lg:pt-0 lg:pl-4">
    <select
      class="bg-gray-100 px-3 py-2 rounded-md w-40 dark:bg-gray-700 dark:text-gray-100"
      (change)="buildDataForChartCalcStaking($event.target.value)"
    >
      <option value="Min">
        {{ 'min' | translate }}
      </option>
      <option value="Hour">
        {{ 'hour' | translate }}
      </option>
      <option value="Day">
        {{ 'day' | translate }}
      </option>
      <option value="Week">
        {{ 'week' | translate }}
      </option>
      <option value="Month" selected="selected">
        {{ 'month' | translate }}
      </option>
      <option value="Year">
        {{ 'year' | translate }}
      </option>
    </select>
    <br>
    <br>
    <p>Cake  <span class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">{{stakingApyCake | number: '1.2-2'}} % APY</span></p>
    <br>
    <p>Masternode  <span class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">{{stakingApyMN | number: '1.2-2'}} % APR</span></p>
    <br>
    <p>Input <span class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">{{stakingCalcOut.apy | number: '1.2-2'}} % APY</span></p>
    <br>
    <div id="chart4">
      <apx-chart
        *ngIf="chartOptions4"
        [series]="chartOptions4?.series"
        [chart]="chartOptions4?.chart"
        [plotOptions]="chartOptions4?.plotOptions"
        [labels]="chartOptions4?.labels"
        [legend]="chartOptions4?.legend"
        [colors]="chartOptions4?.colors"
        [responsive]="chartOptions4?.responsive"
      ></apx-chart>
    </div>
  </div>
</div>
