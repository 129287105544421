<div class="col-sm bg-light margin-col">
  <h2 class="text-lg my-4 dark:text-gray-100">
    {{ 'target-reward-title-lm' | translate }}
  </h2>

  <div class="grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1">
    <div class="lg:pr-4 lg:border-r-2">
      <div class="flex">
        <select
          class="bg-gray-100 h-10 px-3 py-2 rounded-md w-40 mr-2 dark:bg-gray-700 dark:text-gray-100 mb-2"
          (change)="onChangeLmCalculationTargetPool($event.target.value)"
        >
          <option *ngFor='let token of getToken()'
                  [selected]="poolSelected === token"
                  [value]="token">
            {{token}}
          </option>
        </select>
      </div>

      <p class="text-xs mt-1 text-gray-600 dark:text-gray-300">
        {{ 'target-reward-sub-lm' | translate }} pool {{poolSelected}} apr {{pool?.apr | number: '1.1-1'}} %
      </p>

      <div class="mt-5 grid grid-cols-2 grid-rows-4 gap-4">
        <div
          class="relative h-14 input-component w-auto inline-block mr-2"
          [ngClass]="!aimReturnMinPool ? 'empty' : ''"
          title="DFI per {{ 'Min' | translate }}"
        >
          <input
            id="aimReturnMinPool"
            type="number"
            required
            placeholder="Enter dfi amount"
            [(ngModel)]="aimReturnMinPool"
            (ngModelChange)="onChangeAimReturnMinPool($event)"
            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          />
          <label for="aimReturnMinPool" class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
            DFI per {{ 'Min' | translate }}
          </label>
        </div>

        <div
          class="relative h-14 input-component w-auto inline-block mr-2"
          [ngClass]="!aimReturnHourPool ? 'empty' : ''"
          title="DFI per {{ 'hour' | translate }}"
        >
          <input
            id="aimReturnHourPool"
            type="number"
            required
            placeholder="Enter dfi amount"
            [(ngModel)]="aimReturnHourPool"
            (ngModelChange)="onChangeAimReturnHourPool($event)"
            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          />
          <label for="aimReturnHourPool" class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
            DFI per {{ 'hour' | translate }}
          </label>
        </div>

        <div
          class="relative h-14 input-component w-auto inline-block mr-2 dark:text-gray-100"
          [ngClass]="!aimReturnDayPool ? 'empty' : ''"
          title="DFI per {{ 'day' | translate }}"
        >
          <input
            id="aimReturnDayPool"
            type="number"
            required
            placeholder="Enter dfi amount"
            [(ngModel)]="aimReturnDayPool"
            (ngModelChange)="onChangeAimReturnDayPool($event)"
            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          />
          <label for="aimReturnDayPool" class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
            DFI per {{ 'day' | translate }}
          </label>
        </div>

        <div
          class="relative h-14 input-component w-auto inline-block mr-2"
          [ngClass]="!aimReturnMonthPool ? 'empty' : ''"
          title="DFI per {{ 'month' | translate }}"
        >
          <input
            id="aimReturnMonthPool"
            type="number"
            required
            placeholder="Enter dfi amount"
            [(ngModel)]="aimReturnMonthPool"
            (ngModelChange)="onChangeAimReturnMonthPool($event)"
            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
          />
          <label for="aimReturnMonthPool" class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
            DFI per {{ 'month' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="mt-4 lg:mt-0 lg:pl-4 dark:text-gray-100">
      <h1 class="text-2xl font-bold mb-4 w-full text-center">{{ 'income.calc' | translate }}</h1>
      <div class="py-2 grid grid-flow-col grid-cols-4 select-none border-b">
        <div class="text-xl font-semibold">
          DFI per {{ 'month' | translate }}
        </div>
        <div class="text-xl text-center">
          {{dfiNeededForAimReturnMonthPool | number: '1.2-2' }} {{isCrypto()? 'DFI' : 'DUSD'}}
        </div>
        <div class="text-xl text-center">
          {{btcNeededForAimReturnMonthPool | number: '1.3-3' }} {{poolLmCalculationTargetReturn}}
        </div>
        <div class="text-xl text-right">
          <span class="text-pink-500">
            {{anteilNeededForAimReturnMonthPool | number: '1.4-4' }}
          </span> %
          {{ 'share' | translate }}
        </div>
      </div>

      <div class="py-2 grid grid-flow-col grid-cols-4 select-none border-b">
      <div class="text-xl font-semibold">
        DFI per {{ 'day' | translate }}
      </div>
        <div class="text-xl text-center">
          {{dfiNeededForAimReturnDayPool | number: '1.2-2' }} {{isCrypto()? 'DFI' : 'DUSD'}}
        </div>
        <div class="text-xl text-center">
          {{btcNeededForAimReturnDayPool | number: '1.3-3' }} {{poolLmCalculationTargetReturn}}
        </div>
        <div class="text-xl text-right">
          <span class="text-pink-500">
            {{anteilNeededForAimReturnDayPool | number: '1.4-4' }}
          </span> %
          {{ 'share' | translate }}
        </div>
      </div>

      <div class="py-2 grid grid-flow-col grid-cols-4 select-none border-b">
        <div class="text-xl font-semibold">
          DFI per {{ 'hour' | translate }}
        </div>
        <div class="text-xl text-center">
          {{dfiNeededForAimReturnHourPool | number: '1.2-2' }} {{isCrypto()? 'DFI' : 'DUSD'}}
        </div>
        <div class="text-xl text-center">
          {{btcNeededForAimReturnHourPool | number: '1.3-3' }} {{poolLmCalculationTargetReturn}}
        </div>
        <div class="text-xl text-right">
          <span class="text-pink-500">
            {{anteilNeededForAimReturnHourPool | number: '1.4-4' }}
          </span> %
          {{ 'share' | translate }}
        </div>
      </div>

      <div class="py-2 grid grid-flow-col grid-cols-4 select-none border-b">
        <div class="text-xl font-semibold">
          DFI per {{ 'Min' | translate }}
        </div>
        <div class="text-xl text-center">
          {{dfiNeededForAimReturnMinPool | number: '1.2-2' }} {{isCrypto()? 'DFI' : 'DUSD'}}
        </div>
        <div class="text-xl text-center">
          {{btcNeededForAimReturnMinPool | number: '1.3-3' }} {{poolLmCalculationTargetReturn}}
        </div>
        <div class="text-xl text-right">
          <span class="text-pink-500">
            {{anteilNeededForAimReturnMinPool | number: '1.4-4' }}
          </span> %
          {{ 'share' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
