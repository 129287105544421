<div
  class='flex flex-col lg:flex-row lg:flex-auto'
>
  <!-- [ngClass]="{ 'grid-rows-2 lg:grid-cols""-2': !hideHoldings }" -->
  <div class='mb-4 lg:mb-0 order-2 lg:order-1 flex-1' *ngIf='!hideHoldings'>
    <h2 class='text-lg dark:text-gray-100'>{{ 'value.dist' | translate }}</h2>
    <br>
    <div class='order-2 lg:order-1' *ngIf='chartOptions && chartOptions.series.length > 0'>
      <apx-chart *ngIf='chartOptions && chartOptions.series.length > 0'
                 [series]='chartOptions?.series'
                 [chart]='chartOptions?.chart'
                 [xaxis]='chartOptions?.xaxis'
                 [colors]='chartOptions?.colors'
                 [plotOptions]='chartOptions?.plotOptions'
                 [fill]='chartOptions?.fill'
                 [dataLabels]='chartOptions?.dataLabels'
                 [legend]='chartOptions?.legend'
                 [theme]='{ mode: getTheme() }'
      ></apx-chart>
    </div>
  </div>
  <div class='order-1 lg:order-2 flex-1'>
    <div>

      <h3 class='text-lg font-semibold mt-4 mb-1 dark:text-gray-100'>
        {{ "balance" | translate }} DFI
      </h3>

      <div class='grid grid-flow-col grid-cols-2 md:gap-2'>
        <span class='grid grid-flow-row auto-rows-max grid-cols-2 lg:grid-cols-3 gap-3 border-b pb-2 col-span-2 mb-2'>
          <div class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getDfiCount() | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400 dark:text-gray-300'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getDfiValueUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
             {{ 'income.all' | translate }}
            </div>
          </div>
          <div *ngIf='getStakingValue() > 0' class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getStakingValue() | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400 dark:text-gray-300'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getStakingValueUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              Staking
            </div>
          </div>

          <div *ngIf='getDfiCountLM() > 0' class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getDfiCountLM() | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400 dark:text-gray-300'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getDfiCountLMUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              LM
            </div>
          </div>

          <div *ngIf='getDfiCountWallet() > 0' class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getDfiCountWallet()| number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400 dark:text-gray-300'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getDfiCountWalletUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              Wallet
            </div>
          </div>

          <div *ngIf="getCollateralCountVaults('DFI') > 0" class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getCollateralCountVaults('DFI') | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getDfiInVaultUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              Vaults
            </div>
          </div>

           <div *ngIf='dfiInMasternodes - getFreezerDfiCount() > 0 ' class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : dfiInMasternodes - getFreezerDfiCount() | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                           [usdPrice]='getMasternodeDfiUsdWithoutFreeezer()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              Masternodes
            </div>
          </div>



           <div *ngIf='getFreezerDfiCount()> 0' class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              {{isIncognitoModeOn ? '****' : getFreezerDfiCount() | number: '1.2-2' }}
              <br />
              <div
                class='text-xs text-gray-400'
              >
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getFreezerDfiUsd()'></app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>
            </div>
            <div class='inline-block h-full align-middle dark:text-gray-100'>
              Freezer
            </div>
          </div>

        </span>
      </div>

      <h3 class='text-lg font-semibold mt-4 mb-1 dark:text-gray-100'>
        {{ "balance" | translate }}  {{ 'income.all' | translate }}
      </h3>

      <div
        class='grid grid-flow-row auto-rows-max grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 border-b pb-2 col-span-2 mb-2'>


        <div *ngFor='let l of getHoldingTokens()' class='text-lg dark:text-gray-100 flex'>
          <div class='inline-block h-full align-middle dark:text-gray-100 pr-3'>
            {{l.name}}
          </div>
          <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis'>
            {{isIncognitoModeOn ? '****' : l.holding | number: '1.3-3'}}
            <div class='text-xs text-gray-400'>
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='l.holdingUsd'></app-price>
              <div *ngIf='isIncognitoModeOn'>*****</div>
            </div>
          </div>
        </div>


      </div>
      <div *ngIf='getAllVaultsFromAllAddresses().length > 0'>
        <h3
          class='text-lg font-semibold mt-4 mb-1 dark:text-gray-100'>
          {{ "balance" | translate }} Vaults (Oracle)
        </h3>

        <div *ngFor='let v of getAllVaultsFromAllAddresses()' class='md:gap-2 '>
          <div class='text-xl relative'>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
              &nbsp;<a class='text-pink-500 hover:underline'
                       target='_blank'
                       href='https://defiscan.live/vaults/{{v.id}}'>{{isIncognitoModeOn ? '****' : getShortOfId(v.id)}}
              - <span *ngIf='v.state.toUpperCase() === "IN LIQUIDATION"'>&#10060;</span>
              <span *ngIf='v.state.toUpperCase() === "EMPTY"'>&#10060;</span>
              <span *ngIf='v.state.toUpperCase() !== "IN LIQUIDATION"'>&#9989;</span> -
              Ratio {{isIncognitoModeOn ? '****' : v.vaultRatio}}
              % - Min {{ isIncognitoModeOn ? '****' : v.loanScheme?.minColRatio}} %
              - {{ isIncognitoModeOn ? '****' : v.loanScheme?.interestRate}} %</a> &nbsp;

              <br />
              <div *ngIf='v.vaultRatio > -1'
                   class='rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 mx-2 my-2 cursor-pointer inline-block text-center'>
                Next In {{getBlockToNextOracle()}} ~
                <span class='text-xl'>{{isIncognitoModeOn ? '****' : v.nextVaultRation}} % </span>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>&nbsp;
              <span *ngIf='v.nextVaultRation < v.vaultRatio'>📉</span>
              <span *ngIf='v.nextVaultRation >= v.vaultRatio'>📈</span>
              <br>
              <div *ngIf='v.vaultRatio > -1'
                   class='min-h-7 inline-block rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-sm px-2 mx-2 my-2 py-1 cursor-pointer'>
                <span class='text-l'><app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                                                [usdPrice]='v.collateralValue'></app-price></span>
                -> Next <span class='text-l'><app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                                                        [usdPrice]='v.nextCollateralValue'></app-price></span>
              </div>
              <div *ngIf='v.vaultRatio > -1'
                   class='min-h-7 inline-block rounded-full bg-blue-500 hover:bg-gray-800 transition-all text-white text-sm px-2 mx-2 py-1 my-2 cursor-pointer'>
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='v.loanValue'></app-price>
                ->
                Next
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='v.nextLoanValue'></app-price>
              </div>
              <div *ngIf='isIncognitoModeOn'>****</div>
              <div *ngIf='v.vaultRatio > -1'
                   class='rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 mx-2 my-2 cursor-pointer inline-block text-center'>
                {{ 'value.interest' | translate }}
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                           [usdPrice]='v.interestUsdValue'>
                </app-price>
                <div *ngIf='isIncognitoModeOn'>****</div>
              </div>&nbsp;
            </div>
          </div>

        </div>
        <div *ngIf='getVaultsCollateralUsd() > 0'>
          <h3
            class='border-t text-lg font-semibold mt-4 mb-1 dark:text-gray-100'>
            Collaterals
          </h3>
          <div
            class='min-h-7 inline-block rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-xl px-2 py-1 cursor-pointer'>
            <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getVaultsCollateralUsd()'></app-price>
            <span class='text-sm'></span>

          </div>
          <br>

          <div
            class='grid grid-flow-row auto-rows-max grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3  pb-2 col-span-2 mb-2'>
            <div *ngFor='let l of getCollateralTokens()' class='text-xl relative'>
              <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis pr-2'>
                {{isIncognitoModeOn ? '****' : l.holding | number: '1.2-2' }}
                <br />
                <div
                  class='text-xs text-gray-400 dark:text-gray-300'
                >
                  <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                             [usdPrice]='l.holdingUsd'></app-price>
                  <div *ngIf='isIncognitoModeOn'>****</div>
                </div>
              </div>
              <div class='inline-block h-full align-middle dark:text-gray-100'>
                {{l.name}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf='getVaultsLoansValueUsd() > 0'>
          <h3
            class='border-t text-lg font-semibold mt-4 mb-1 dark:text-gray-100'>
            Loans
          </h3>
          <div
            class='min-h-7 inline-block rounded-full bg-blue-500 hover:bg-gray-800 transition-all text-white text-xl px-2 py-1 cursor-pointer'>
            <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='getVaultsLoansValueUsd()'></app-price>
            <span class='text-sm'></span></div>
          <div *ngIf='isIncognitoModeOn'>****</div>

        </div>
        <div
          class='grid grid-flow-row auto-rows-max grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 pb-2 col-span-2 mb-2'>

          <div *ngFor='let l of getLoanTokens()' class='text-lg dark:text-gray-100 flex'>
            <div class='inline-block h-full align-middle dark:text-gray-100 pr-3'>
              {{l.token}}
            </div>
            <div class='text-pink-500 font-semibold inline-block overflow-hidden overflow-ellipsis'>
              {{isIncognitoModeOn ? '****' : getLoanCountVaults(l.token) | number: '1.5-5'}}
              <div class='text-xs text-gray-400'>
                <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                           [usdPrice]='getLoanCountVaults(l.token) * getUsdPriceOfStockPools(l.pool) '></app-price>
                <div *ngIf='isIncognitoModeOn'>*****</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentPage === 'holdings'"
     class='grid grid-flow-row grid-cols-1 auto-rows-max lg:grid-cols-2 lg:gap-4 gap-6'
>
  <div class='bg-gray-100 rounded-md p-2 dark:bg-gray-600 dark:text-gray-100'>
    <h3 class='text-lg font-bold mb-2'>{{ 'holdings.wallet' | translate }} Wallet</h3>
    <div class='grid grid-flow-row grid-cols-2 auto-rows-max gap-2'>
      <div>
        <div *ngIf='getDfiCountWallet() > 0'>DFI</div>
        <div *ngIf='getDfiCountWallet() > 0'>
          <div
            class='inline-block rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer'>
            <div *ngIf='!isIncognitoModeOn'>{{getDfiCountWallet() | number: '1.3-3'}} ~
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                         [usdPrice]='getDfiCountWallet() * getPool("BTC")?.priceB'></app-price>
            </div>
            <div *ngIf='isIncognitoModeOn'>****</div>
          </div>
        </div>
      </div>
      <div *ngIf='dfiInStaking > 0'>
        <div *ngIf='dfiInStaking > 0'>DFI in Cake Staking</div>
        <div *ngIf='dfiInStaking > 0'>
          <div
            class='rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block text-center'>
            <div *ngIf='!isIncognitoModeOn'>{{dfiInStaking | number: '1.1-1'}} ~
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                         [usdPrice]='dfiInStaking * getPool("BTC")?.priceB'></app-price>
            </div>
            <div *ngIf='isIncognitoModeOn'>****</div>
          </div>
        </div>
      </div>
      <div *ngIf='dfiInLockStaking > 0'>
        <div *ngIf='dfiInLockStaking > 0'>DFI in Lock Staking</div>
        <div *ngIf='dfiInLockStaking > 0'>
          <div
            class='rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block text-center'>
            <div *ngIf='!isIncognitoModeOn'>{{dfiInLockStaking | number: '1.1-1'}} ~
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                         [usdPrice]='dfiInLockStaking * getPool("BTC")?.priceB'></app-price>
            </div>
            <div *ngIf='isIncognitoModeOn'>****</div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf='dfiInMasternodes > 0'>DFI in Masternodes</div>
        <div *ngIf='dfiInMasternodes > 0'>
          <div
            class='inline-block rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer text-center'>
            <div *ngIf='!isIncognitoModeOn'>{{dfiInMasternodes  | number: '1.1-1'}} ~
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                         [usdPrice]='dfiInMasternodes * getPool("BTC")?.priceB'></app-price>
            </div>
            <div *ngIf='isIncognitoModeOn'>****</div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf='freezer5?.length > 0 || freezer10?.length > 0'>DFI in Freezer</div>
        <div *ngIf='freezer5?.length > 0 || freezer10?.length > 0'>
          <div
            class='inline-block rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer text-center'>
            <div *ngIf='!isIncognitoModeOn'>{{getFreezerDfiCount() | number: '1.1-1'}} ~
              <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat'
                         [usdPrice]='getFreezerDfiCount()  * getPool("BTC")?.priceB'></app-price>
            </div>
            <div *ngIf='isIncognitoModeOn'>****</div>
          </div>
        </div>
      </div>
      <div *ngFor='let w of getWalletTokens()'>
        <div>{{w.name}}</div>
        <div
          class='inline-block rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer'>
          <div *ngIf='!isIncognitoModeOn'>{{w.holding | number: '1.5-5'}} ~
            <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='w.holdingUsd'></app-price>
          </div>
          <div *ngIf='isIncognitoModeOn'>****</div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf='autoLoadData' class='bg-gray-100 rounded-md p-2 dark:bg-gray-600 dark:text-gray-100'>
    <h3 class='text-lg font-bold mb-2'>{{ 'holdings.lp' | translate }}</h3>
    <div class='grid grid-flow-row grid-cols-2 auto-rows-max gap-2'>
      <div *ngFor='let w of getLpTokens()'>
        <div>{{w.name}}</div>
        <div>
             <span
               class='rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer'>
               {{isIncognitoModeOn ? '****' : w.holding| number: '1.3-3'}}
               ~  <app-price *ngIf='!isIncognitoModeOn' [fiat]='fiat' [usdPrice]='w.holdingUsd'></app-price>
             </span>
        </div>
      </div>
    </div>
  </div>
</div>

