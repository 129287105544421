
<div
  class="h-screen sm:max-h-screen bg-gray-100 dark:bg-gray-800"
  xmlns="http://www.w3.org/1999/html"
  [ngClass]="menu ? 'overflow-hidden' : ''"
>
  <div class="xl:hidden w-full bg-pink-500 text-white overflow-auto">
    <div class="flex items-center justify-center whitespace-nowrap sitemessage" >
      <div class="mr-4">
        dUSD
        {{getDUSDPrice()}} $ - Fee {{getDUSDFee()}} % - {{ 'value.interest' | translate}} {{getInterest('DUSD')}} %
         / DFI
        <app-price [fiat]="fiat" [usdPrice]="poolBtc ? poolBtc?.priceB : 0"></app-price>
        /
        &Oslash; APR {{avgApr}} %
      </div>

      <div *ngIf="wallet" class="inline-block h-full items-center border-l pl-4" >
        {{ 'balance' | translate }}:
        <app-price *ngIf="!isIncognitoModeOn" [fiat]="fiat" [usdPrice]="getAllValuesUsdPriceWithoutLoan()"></app-price>
        <div class="inline-block" *ngIf="isIncognitoModeOn">****</div>
      </div>
    </div>
  </div>


  <header class="h-auto py-1 px-2 sm:flex sm:h-24 sm:py-3 sm:justify-start relative">
    <a
      class="sm:hidden absolute left-2 flex cursor-pointer hover:text-pink-500 dark:text-gray-100"
      (click)="toggleMenu()"
    >
      <app-icon name="menu"></app-icon>
    </a>

    <a (click)="handlePage('dashboard')" class="mx-auto sm:m-0 h-10 sm:h-auto relative block w-28 cursor-pointer">
      <img
        src="assets/img/Defi-income.svg"
        class="h-full mx-auto"
        alt="DefiChain-Income Logo"
      />
    </a>

    <div *ngIf="loggedIn" class="hidden lg:inline ml-4 h-full py-3 text-sm dark:text-gray-100">
      <strong>{{ 'login3' | translate }}</strong>
      <div>
        {{isIncognitoModeOn ? '****' : loggedInAuth}}
      </div>

      <div *ngIf="timestamp" class="hidden lg:inline-block dark:text-gray-400 text-xs">
        {{ 'lastUpdate' | translate }}
        {{ timestamp }}
      </div>
    </div>

    <div *ngIf="loggedIn" class="hidden md:flex dark:text-gray-100 pl-5 justify-center items-center">
      <input
        type="checkbox"
        id="incognito-eye"
        [(ngModel)]="isIncognitoModeOn"
        [ngClass]="{ 'isChecked': isIncognitoModeOn }"
        class="hidden"
      />
      <label for="incognito-eye" class="cursor-pointer inline-block pt-1">
        <app-icon class="pointer-events-none" *ngIf="!isIncognitoModeOn" name="eye"></app-icon>
        <app-icon class="pointer-events-none" *ngIf="isIncognitoModeOn" name="eye-close"></app-icon>
      </label>
    </div>

    <div class="flex justify-center items-center">
      <div (click)="reload()" class="absolute sm:relative right-2 top-2 sm:top-auto cursor-pointer hover:text-pink-500 dark:text-gray-100 w-10 h-10 sm:w-6 sm:h-6 sm:ml-5">
        <app-icon class="pointer-events-none" name="refresh"></app-icon>
      </div>
    </div>

    <div class="flex items-center justify-center mt-2 md:mt-0">
      <button title="Buy Crypto" onClick =" window.open('https://dfx.swiss/app?code=158-310','_blank')" (click)="trackDFX()" class="bg-pink-500 rounded-md w-full p-2 sm:pl-5 sm:pr-12 text-white focus:outline-none relative">
        <div>
          Buy Crypto
        </div>
        <app-icon class="pointer-events-none" name="cart" class="text-white w-8 h-8 inline-block absolute right-5 sm:right-2 top-1/2 transform -translate-y-1/2"></app-icon>
      </button>
    </div>

    <div class="flex mt-2 sm:absolute sm:right-6 whitespace-nowrap self-center justify-center items-center">
      <div class="hidden xl:inline-block h-full mr-2 sm:mr-5 items-center">
        <span
          class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">
          dUSD
          {{getDUSDPrice()}} $ - Fee {{getDUSDFee()}} % - {{ 'value.interest' | translate}} {{getInterest('DUSD')}} %
           / DFI
           <app-price [fiat]="fiat" [usdPrice]="poolBtc ? poolBtc?.priceB : 0"></app-price>
          /
          &Oslash; APR {{avgApr}} %
        </span>
      </div>
      <div *ngIf="wallet" class="hidden xl:inline-block h-full md:mr-5 items-center">
        <span
          class="rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-sm px-2 py-1 cursor-pointer">
          <app-price *ngIf="!isIncognitoModeOn" [fiat]="fiat" [usdPrice]="getAllValuesUsdPriceWithoutLoan()"></app-price>
          <div class="inline-block" *ngIf="isIncognitoModeOn">****</div>
        </span>
      </div>

      <div class="hidden md:inline-block h-full mr-2 lg:mr-5 items-center">
        <app-changelog></app-changelog>
      </div>

      <div class="hidden md:inline-block dark:text-gray-100 sm:mx-2" *ngIf="apiOnline">
        <div class="inline-block rounded bg-green-400 h-2 w-2"></div>
        Api Online
      </div>

      <div
        class="fixed md:relative left-0 right-0 top-0 bg-red-600 md:bg-transparent z-50 text-white md:text-current text-center md:text-center md:inline-block dark:text-gray-100"
        *ngIf="!apiOnline"
      >
        <div class="hidden md:inline-block rounded bg-red-600 h-2 w-2"></div>
        Api Offline
      </div>

      <select
        class="hidden sm:inline ml-2 lg:ml-4 bg-gray-100 dark:bg-gray-800 dark:text-gray-100 border-none lg:px-2 uppercase text-lg focus:outline-none"
        (change)="useLanguage($event.target.value)"
        [value]="lang"
      >
        <option value="de" [selected]="isLangSet('de')">DE</option>
        <option value="nl" [selected]="isLangSet('nl')">NL</option>
        <option value="en" [selected]="isLangSet('en')">EN</option>
        <option value="ru" [selected]="isLangSet('ru')">RU</option>
        <option value="es" [selected]="isLangSet('es')">ES</option>
        <option value="fr" [selected]="isLangSet('fr')">FR</option>
      </select>

      <select
        class="hidden sm:inline bg-gray-100 dark:bg-gray-800 dark:text-gray-100 border-none lg:px-2 uppercase text-lg focus:outline-none text-center"
        (change)="onChangeFiat($event.target.value)"
        [value]="fiat"
        style="text-align-last: center;"
      >
        <option class="text-center" value="BTC">&#x20BF;</option>
        <option class="text-center" value="ETH">ETH</option>
        <option class="text-center" value="USD" selected>$</option>
        <option class="text-center" value="DFI">DFI</option>
        <option class="text-center" value="EUR">€</option>
        <option class="text-center" value="GBP">£</option>
        <option class="text-center" value="CHF">CHF</option>
        <option class="text-center" value="AUD">A$</option>
        <option class="text-center" value="RUB">&#8381;</option>
        <option class="text-center" value="JPY">&#165;</option>
        <option class="text-center" value="CAD">C$</option>
        <option class="text-center" value="CNY">&#20803;</option>
        <option class="text-center" value="SGD">S$</option>
        <option class="text-center" value="HKD">H$</option>
      </select>
    </div>
  </header>

  <div
    class="fixed top-0 h-screen max-h-screen sm:hidden z-50 transition duration-1000 ease-in-out transform bg-gray-100 dark:bg-gray-800 dark:text-gray-100 overflow-y-auto mb-20"
    *ngIf="menu"
    [ngClass]="menu ? 'w-full' : ''"
  >
    <div class="relative">
      <div class="sticky top-0 h-14 w-full bg-gray-100 dark:bg-gray-800 shadow-xl dark:shadow-gray-800/50 shadow-gray-100/50">
        <a
          (click)="handlePage('dashboard')"
          class="absolute w-28 h-12 py-1 left-1/2 transform -translate-x-1/2"
        >
          <img
            src="../assets/img/Defi-income.svg"
            alt="DefiChain-Income Logo"
            class="h-full mx-auto"
          />
        </a>
        <div
          class="absolute right-5 top-5 z-10 cursor-pointer"
          (click)="toggleMenu()"
        >
          <app-icon class="w-5 h-5 block" name="close"></app-icon>
        </div>
      </div>

      <div class="w-full h-auto px-8">
        <div class="h-full w-full">
          <a
            (click)="handlePage('dashboard')"
            [ngClass]="{ 'text-pink-500': currentPage === 'dashboard' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="home"></app-icon>
            <div class="inline self-center ml-2 text-xl">Dashboard</div>
          </a>

          <a
            (click)="handlePage('holdings')"
            [ngClass]="{ 'text-pink-500': currentPage === 'holdings' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="doughnut-chart"></app-icon>
            <div class="inline self-center ml-2 text-xl">{{ 'holdings.title' | translate }}</div>
          </a>

          <a
            (click)="handlePage('income')"
            [ngClass]="{ 'text-pink-500': currentPage === 'income' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="income"></app-icon>
            <div class="inline self-center ml-2 text-xl">{{ 'income.word' | translate }}</div>
          </a>
          <a
            (click)="handlePage('history')"
            [ngClass]="{ 'text-pink-500': currentPage === 'history' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="clock"></app-icon>
            <div class="inline self-center ml-2 text-xl">{{ 'history-app.title' | translate }}</div>
          </a>

          <a
            (click)="handlePage('dex')"
            [ngClass]="{ 'text-pink-500': currentPage === 'dex' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="bar-chart"></app-icon>
            <div class="inline self-center ml-2 text-xl">DEX</div>
          </a>

          <a
            (click)="handlePage('defi')"
            [ngClass]="{ 'text-pink-500': currentPage === 'defi' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="line-chart"></app-icon>
            <div class="inline self-center ml-2 text-xl">DFI</div>
          </a>

          <a
            (click)="handlePage('calculator')"
            [ngClass]="{ 'text-pink-500': currentPage === 'calculator' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="calculator"></app-icon>
            <div class="inline self-center ml-2 text-xl">{{ 'income.calc' | translate }}</div>
          </a>

          <a
            (click)="handlePage('newsletter')"
            [ngClass]="{ 'text-pink-500': currentPage === 'newsletter' }"
            class="hover:text-pink-400 py-3 text-center cursor-pointer flex"
          >
            <app-icon name="newsletter"></app-icon>
            <div class="inline self-center ml-2 text-xl">{{ 'newsletter.title' | translate }}</div>
          </a>

          <div class="pt-3 mt-3 border-t border-gray-500">
            <a
              (click)="handlePage('info')"
              [ngClass]="{ 'text-pink-500': currentPage === 'info' }"
              class="hover:text-pink-400 text-center cursor-pointer flex"
            >
              <app-icon name="info"></app-icon>
              <div class="inline self-center ml-2 text-xl">Info</div>
            </a>
            <a
              (click)="handlePage('settings')"
              [ngClass]="{ 'text-pink-500': currentPage === 'settings' }"
              class="hover:text-pink-400 text-center cursor-pointer flex"
            >
              <div class="w-10 h-10">
                <app-icon name="settings"></app-icon>
              </div>
              <div class="inline self-center ml-2 text-xl">{{ 'setting' | translate }}</div>
            </a>
          </div>
        </div>
      </div>

      <div class="mx-8 mt-4 mb-32">
        <select
          class="bg-gray-100 border-none pr-2 uppercase text-lg focus:outline-none h-10 mr-2 dark:bg-gray-700"
          (change)="useLanguage($event.target['value'])"
          [value]="lang"
        >
          <option value="de" [selected]="isLangSet('de')">DE</option>
          <option value="en" [selected]="isLangSet('en')">EN</option>
          <option value="es" [selected]="isLangSet('es')">ES</option>
          <option value="ru" [selected]="isLangSet('ru')">RU</option>
          <option value="fr" [selected]="isLangSet('fr')">FR</option>
        </select>

        <select
          class="bg-gray-100 border-none px-2 uppercase text-lg focus:outline-none text-center h-10 dark:bg-gray-700"
          (change)="onChangeFiat($event.target.value)"
          [value]="fiat"
          style="text-align-last: center;"
        >
          <option class="text-center" value="BTC">&#x20BF;</option>
          <option class="text-center" value="ETH">ETH</option>
          <option class="text-center" value="USD" selected>$</option>
          <option class="text-center" value="DFI">DFI</option>
          <option class="text-center" value="EUR">€</option>
          <option class="text-center" value="GBP">£</option>
          <option class="text-center" value="CHF">CHF</option>
          <option class="text-center" value="AUD">A$</option>
          <option class="text-center" value="RUB">&#8381;</option>
          <option class="text-center" value="JPY">&#165;</option>
          <option class="text-center" value="CAD">C$</option>
          <option class="text-center" value="CNY">&#20803;</option>
          <option class="text-center" value="SGD">S$</option>
          <option class="text-center" value="HKD">H$</option>
        </select>

        <div *ngIf="timestamp" class="dark:text-gray-400 text-xs -mb-2 mt-2">
          {{ 'lastUpdate' | translate }}
          {{ timestamp }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="timestamp" class="hidden sm:inline lg:hidden dark:text-gray-400 fixed right-8 bottom-2 text-xs">
    {{ 'lastUpdate' | translate }}
    {{ timestamp }}
  </div>


  <div class="dashboard-height dark:bg-gray-800 sm:flex w-full">
    <div class="hidden sm:inline-block transition duration-300 ease-in-out transform sm:w-24">
      <div class="flex flex-grow flex-1 flex-col h-full overflow-hidden">
        <div class="flex flex-1 flex-col overflow-y-auto">
          <a
            (click)="handlePage('dashboard')"
            [ngClass]="currentPage === 'dashboard' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="Dashboard"
          >
            <app-icon name="home"></app-icon>
          </a>
          <a
            (click)="handlePage('holdings')"
            [ngClass]="currentPage === 'holdings' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="{{ 'holdings.title' | translate }}"
          >
            <app-icon name="doughnut-chart"></app-icon>
          </a>
          <a
            (click)="handlePage('income')"
            [ngClass]="currentPage === 'income' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="{{ 'income.word' | translate }}"
          >
            <app-icon name="income"></app-icon>
          </a>
          <a
            (click)="handlePage('history')"
            [ngClass]="currentPage === 'history' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="{{ 'history-app.title' | translate }}"
          >
            <app-icon name="clock"></app-icon>
          </a>

          <a
            (click)="handlePage('dex')"
            [ngClass]="currentPage === 'dex' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="DEX"
          >
            <app-icon name="bar-chart"></app-icon>
          </a>

          <a
            (click)="handlePage('defi')"
            [ngClass]="currentPage === 'defi' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="DEFI"
          >
            <app-icon name="line-chart"></app-icon>
          </a>
          <a
            (click)="handlePage('calculator')"
            [ngClass]="currentPage === 'calculator' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="{{ 'income.calc' | translate }}"
          >
            <app-icon name="calculator"></app-icon>
          </a>
          <a
            (click)="handlePage('newsletter')"
            [ngClass]="currentPage === 'newsletter' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 py-3 text-center cursor-pointer"
            title="{{ 'newsletter.title' | translate }}"
          >
            <app-icon name="newsletter"></app-icon>
          </a>
        </div>

        <div class="flex-2 justify-end self-end mx-8">
          <a
            (click)="handlePage('info')"
            [ngClass]="currentPage === 'info' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 my-2 block cursor-pointer text-center"
            title="Info"
          >
            <app-icon name="info"></app-icon>
          </a>
          <a
            (click)="handlePage('settings')"
            [ngClass]="currentPage === 'settings' ? 'text-pink-500' : 'dark:text-gray-100'"
            class="hover:text-pink-400 my-2 block cursor-pointer"
            title="{{ 'setting' | translate }}"
          >
            <div class="w-10 h-10 mx-auto">
              <app-icon name="settings"></app-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="sm:flex-grow items-center sm:mt-0 sm:pl-0 sm:pr-4">
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="large"
        color="#FF00AF"
        type="pacman"
      >
        <p style="font-size: 20px; color: white">Loading...</p>
      </ngx-spinner>

      <div
        class="overflow-hidden w-full min-h-screen sm:min-h-0 h-full sm:h-97% lg:h-full rounded-xl py-3 bg-white dark:bg-gray-800 mx-auto sm:mb-5 lg:mb-o">
        <div class="sm:overflow-auto h-full max-w-full px-3 relative">
          <container-element [ngSwitch]="currentPage">
            <div *ngSwitchCase="'holdings'">
              <div>
                <h1 class="text-3xl mb-4 text-pink-500">{{ 'holdings.title' | translate }}</h1>

                <div *ngIf="wallet && poolBtc && pools && adressBalances && dataLoaded">
                  <app-value
                    [isIncognitoModeOn]="isIncognitoModeOn"
                    [fiat]="fiat"
                    [pools]='pools'
                    [dfiInStaking]='dfiInStaking'
                    [dfiInLockStaking]='dfiInLockStaking'
                    [dfiInMasternodes]="dfiInMasternodes"
                    [adressBalances]="adressBalances"
                    [freezer5]="adressesMasternodesFreezer5"
                    [freezer10]="adressesMasternodesFreezer10"
                    [vaultsOfAllAddresses]='vaultsOfAllAddresses'
                    [cexPrice]='priceDFICEX'
                    [rewards]='rewards'
                    [autoLoadData] = 'autoLoadData'
                    [currentPage]='currentPage'
                    [income]='income'
                  ></app-value>
                </div>
              </div>
              <br>
              <div *ngIf="!oneTrackingAddress && loggedInAuth" class="bg-gray-100 rounded-md p-2 dark:bg-gray-600 dark:text-gray-100">
                <h3 class='text-lg font-bold mb-2'>Total Value History</h3>
                <div
                  class='relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in'>
                  <input
                    type='checkbox'
                    id='historyChartOn'
                    [(ngModel)]='isValueChartOn'
                    (ngModelChange)='toggleTotalValueChart()'
                    [ngClass]="{ 'isChecked': isValueChartOn }"
                    class='toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none'
                  />
                  <label
                    for='historyChartOn'
                    class='toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer'
                  ></label>
                </div>
                <br>
                <div class="hidden md:inline-block h-full my-2 items-center m">
                  <app-history-modal [history]='userHistory'  [key]='loggedInAuth'></app-history-modal>
                </div>
                <br>
                <apx-chart *ngIf='isValueChartOn && isUserHistoryForValue()'
                           [series]='chartOptions?.series'
                           [chart]='chartOptions?.chart'
                           [xaxis]= 'chartOptions?.xaxis'
                           [stroke]='chartOptions?.stroke'
                           [markers]='chartOptions?.markers'
                           [tooltip]='chartOptions?.tooltip'
                           [yaxis]='chartOptions?.yaxis'
                           [grid]='chartOptions?.grid'
                           [legend]='chartOptions?.legend'
                           [title]='chartOptions?.title'
                           [theme]='chartOptions?.theme'
                ></apx-chart>
              </div>
            </div>
           <div *ngSwitchCase="'income'">
              <app-income
                [isIncognitoModeOn]="isIncognitoModeOn"
                [poolBtc]="poolBtc"
                [fiat]="fiat"
                [stakingOut]="stakingOut"
                [dfiInStaking]="dfiInStaking"
                [stakingApy]="stakingApy"
                [dfiInLockStaking]='dfiInLockStaking'
                [stakingLockApy]='stakingLockStats?.apy'
                [masternodesApr]="stakingApyMN"
                [masternodesCount]="adressesMasternodes?.length"
                [masternodeOut]="poolMasternodeOut"
                [dfiInMasternodes]="dfiInMasternodes"
                [getDfiCountInLM]="getDfiCountInLM()"
                [getLMUsd]="getLMUsd()"
                [income]='income'
                [poolAllOut]='poolAllOut'
                [lmOut]='lmOut'
              ></app-income>

              <h1 class="text-xl font-semibold text-pink-500 mt-3 mb-2">
                {{ 'your' | translate }} pools
              </h1>
              <div class="grid grid-flow-row grid-cols-1 auto-rows-max lg:grid-cols-2 lg:gap-4 gap-6">

                <!-- LM POOLS -->
                <app-pool-income *ngFor='let l of getIncomePools()'
                                 [poolPairsOcean]='poolPairsOcean'
                                 [pool]='l.pool'
                                 [poolOut]='l.poolOut'
                                 [poolBtc]='poolBtc'
                                 [anteilAmPool]='l.anteil'
                                 [fiat]='fiat'
                                 [isIncognitoModeOn]=isIncognitoModeOn
                ></app-pool-income>

              </div>
              <br>
              <div *ngIf="!oneTrackingAddress && loggedInAuth" class="bg-gray-100 rounded-md p-2 dark:bg-gray-600 dark:text-gray-100">
                <h3 class="text-lg font-bold mb-2">Total Income per Month History</h3>
                <div
                  class='relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in'>
                  <input
                    type='checkbox'
                    id='valueChartOn'
                    [(ngModel)]='isIncomeChartOn'
                    (ngModelChange)='toggleTotalincomeChart()'
                    [ngClass]="{ 'isChecked': isIncomeChartOn }"
                    class='toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none'
                  />
                  <label
                    for='valueChartOn'
                    class='toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer'
                  ></label>
                </div>
                <br>
                <div class="hidden md:inline-block h-full my-2 items-center m">
                  <app-history-modal [history]='userHistory' [key]='loggedInAuth'></app-history-modal>
                </div>
                <br>
                <apx-chart *ngIf='isIncomeChartOn && isUserHistoryForIncome()'
                           [series]='chartOptions2?.series'
                           [chart]='chartOptions2?.chart'
                           [xaxis]= 'chartOptions2?.xaxis'
                           [stroke]='chartOptions2?.stroke'
                           [markers]='chartOptions2?.markers'
                           [tooltip]='chartOptions2?.tooltip'
                           [yaxis]='chartOptions2?.yaxis'
                           [grid]='chartOptions2?.grid'
                           [legend]='chartOptions2?.legend'
                           [title]='chartOptions2?.title'
                           [theme]='chartOptions2?.theme'
                ></apx-chart>
              </div>
              <br>
            </div>
           <div *ngSwitchCase="'history'">
              <h1 class="text-3xl mb-4 text-pink-500">{{ 'history-app.title' | translate }}</h1>
              <h2 class='text-lg dark:text-gray-100'>{{ 'history-app.h2' | translate }}</h2>
              <br>
              <div class="contents w-full max-h-screen h-full overflow-visible">
                <app-history>
                </app-history>
              </div>
            </div>
            <div *ngSwitchCase="'dex'">
              <h1 class="text-3xl mb-4 text-pink-500">DEX</h1>

              <div class="overflow-x-auto sm:overflow-visible max-w-full">
                <app-dex-statistics *ngIf='cryptoPools && stocksPools'
                                    [fiat]='fiat'
                                    [oceanStats]='oceanStats'
                                    [stocksPools]='stocksPools'
                                    [cryptoPools]='cryptoPools'
                                    [stakingApyCake]='stakingApyCake'
                                    [stakingApyMN]='stakingApyMN'
                                    [MNCount]='masternodeCount'
                                    [MNCount0Freezer]='masternodeCount0Freezer'
                                    [MNCount5Freezer]='masternodeCount5Freezer'
                                    [MNCount10Freezer]='masternodeCount10Freezer'
                                    [burnedDfi]='dfiBurned'
                                    [rewards]='rewards'
                                    [blockTimeUsed]='blocktimeInS'
                                    [blockTimeSecond]='blocktimeInSSecond'
                                    [isIncognitoModeOn]='isIncognitoModeOn'
                                    [correlationDays]='correlationDays'
                                    [priceDFICEX]='priceDFICEX'
                                    [poolPairsOcean]='poolPairsOcean'
                                    [lockStaking]='stakingLockStats'
                                    (callDefiView)="callDefiView($event)">
                </app-dex-statistics>
              </div>
            </div>
            <div *ngSwitchCase="'defi'" class="max-h-screen h-full contents min-h-screen">
              <h1 class="text-3xl mb-4 text-pink-500">DFI TradingView Charts</h1>

              <app-dfi-statistics [lang]="lang" [stocksPools]='stocksPools'></app-dfi-statistics>
            </div>
            <div *ngSwitchCase="'calculator'">
              <app-calculator-page
                [stakingApy]="stakingApy"
                [stakingApyMN]="stakingApyMN"
                [stakingApyCake]="stakingApyCake"
                [fiat]="fiat"
                [pools]='pools'
                [poolBtc]='poolBtc'
              ></app-calculator-page>
            </div>
            <div *ngSwitchCase="'info'">
              <app-info-page
                [trackGraphQL]="trackGraphQL.bind(this)"
                [trackGit]="trackGit.bind(this)"
              ></app-info-page>
            </div>
            <div *ngSwitchCase="'newsletter'">
              <app-newsletter-page
              [newsletter]='newsletter'
              [key]='loggedInAuth'
              ></app-newsletter-page>
            </div>

            <!-- Settings Page -->
            <div *ngSwitchCase="'settings'">
              <div>
                <h1 class="text-3xl mb-4 text-pink-500">{{ 'setting' | translate }}</h1>
                <div class="lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-4">
                  <div class="mb-4 lg:mb-0 dark:text-gray-100 dark:bg-gray-600 rounded p-2 bg-gray-100">
                    <div class="dark:text-gray-100">
                      Refresh Period in seconds:
                    </div>
                    <div class="flex">
                      <div class="w-3/4">
                        <div
                          class="relative h-14 input-component"
                          [ngClass]="!sCountdown ? 'empty' : ''"
                          title="{{ 'refresh6' | translate }}"
                        >
                          <input
                            required
                            [(ngModel)]="sCountdown"
                            type="number"
                            step="1"
                            min="10"
                            id="sCountdown"
                            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                          >
                          <label for="sCountdown"
                                 class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                            {{ 'refresh6' | translate }}
                          </label>
                        </div>
                      </div>
                      <div class="inline-block w-1/4 pl-2 align-top">
                        <button
                          type="button"
                          (click)="onChangeRefreshS()"
                          class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-full h-14 text-white align-top uppercase"
                        >
                          {{ "refresh5" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="w-full text-xs text-gray-400 mt-1">
                      {{ 'refresh3' | translate }}
                      <countdown class="text-pink-400"
                                 [config]="{ leftTime: sCountdownShow, format: 'mm:ss' }"></countdown>
                    </div>


                    <div class="grid grid-cols-2 mt-4">
                      <div class="flex flex-row gap-2">
                        <div class="dark:text-gray-100">
                          Darkmode:
                        </div>
                        <div
                          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            id="darkmode"
                            [(ngModel)]="isDarkModeOn"
                            (ngModelChange)="toggleDarkMode()"
                            [ngClass]="{ 'isChecked': isDarkModeOn }"
                            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                          />
                          <label
                            for="darkmode"
                            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </div>

                      <div class="flex flex-row gap-2">
                        <div class="dark:text-gray-100">
                          Incognito:
                        </div>
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            id="incognito"
                            [(ngModel)]="isIncognitoModeOn"
                            [ngClass]="{ 'isChecked': isIncognitoModeOn }"
                            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                          />
                          <label
                            for="incognito"
                            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </div>
                      <div class="flex flex-row gap-2">
                        <div class="dark:text-gray-100">
                          Freezer in total value calculation:
                        </div>
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            id="freezerInValue"
                            [(ngModel)]="freezerInTotolValue"
                            (ngModelChange)="toggleFreezerInValue()"
                            [ngClass]="{ 'isChecked': freezerInTotolValue }"
                            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                          />
                          <label
                            for="freezerInValue"
                            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="dark:text-gray-100">
                      {{ 'correlationDays' | translate }}
                    </div>
                    <div class="flex">
                      <select class="border dark:border-none bg-gray-100 h-10 px-3 py-2 rounded-md w-40 mr-2 dark:bg-gray-700
                       dark:text-gray-100 mb-2" (change)="onChangeCorrelationForCalc($event.target.value)">
                        <option [selected]="correlationDays === 30" value="30">30</option>
                        <option [selected]="correlationDays === 60" value="60">60</option>
                        <option [selected]="correlationDays === 90" value="90">90</option>
                        <option [selected]="correlationDays === 120" value="120">120</option>
                        <option [selected]="correlationDays === 180" value="180">180</option>
                        <option [selected]="correlationDays === 270" value="270">270</option>
                        <option [selected]="correlationDays === 360" value="360">360</option>
                        <option [selected]="correlationDays === 540" value="540">540</option>
                      </select>

                    </div>

                  </div>
                  <div class="dark:text-gray-100 dark:bg-gray-600 rounded p-2 bg-gray-100 relative">
                    <p *ngIf="!loggedIn">{{ 'loginExplain' | translate }}</p>
                    <p *ngIf="!loggedIn" class="my-3">
                      <button
                        (click)="register()"
                        type="button"
                        class="max-w-xs bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-full h-14 text-white align-top"
                      >
                        {{ 'register' | translate }}
                      </button>
                    </p>

                    <div *ngIf="errorBackend" class="bg-red-600 text-white w-full py-2 px-4 rounded-sm my-2"
                         role="alert">
                      {{errorBackend}}
                    </div>
                    <div
                      *ngIf="successBackend"
                      class="bg-green-500 text-white w-full py-2 px-4 rounded-sm my-2"
                      role="alert"
                    >
                      {{successBackend}}
                    </div>

                    <p *ngIf="!loggedIn" class="py-2">{{ 'login2' | translate }}</p>

                    <div *ngIf="!loggedIn" class="flex">
                      <div class="w-3/4 pr-2">
                        <div
                          class="relative h-14 input-component"
                          [ngClass]="!loggedInAuthInput ? 'empty' : ''"
                          title="{{ 'login21' | translate }}"
                        >
                          <input
                            required
                            [(ngModel)]="loggedInAuthInput"
                            type="text"
                            id="loggedInAuthInput"
                            class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                          >
                          <label for="loggedInAuthInput"
                                 class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                            {{ 'login21' | translate }}
                          </label>
                        </div>
                      </div>
                      <button
                        (click)="login()"
                        type="button"
                        class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-1/4 h-14 text-white align-top"
                      >
                        {{ 'login' | translate }}
                      </button>
                    </div>
                    <div class="flex flex-row gap-2 items-center align-middle">
                      <h2 *ngIf="loggedIn">
                        {{ 'login3' | translate }}
                      </h2>
                      <div
                        *ngIf="loggedInAuth"
                        class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer inline-block mt-1">
                        {{isIncognitoModeOn ? '****' : loggedInAuth}}
                      </div>
                    </div>

                    <div *ngIf="loggedIn" class="mt-2 lg:absolute lg:bottom-2 w-full flex">
                      <button
                        (click)="logout()"
                        type="button"
                        class="border border-pink-500 hover:bg-gray-200 transform transition-all delay-150 focus:outline-none rounded-md w-2/4 h-14 text-pink-500 align-top mr-2 dark:hover:bg-gray-700"
                      >
                        Logout
                      </button>

                      <button
                        (click)="update()"
                        type="button"
                        class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-2/4 lg:w-1/4 h-14 text-white align-top uppercase"
                      >
                        {{ 'save' | translate }}
                      </button>
                    </div>
                  </div>
                </div>


                <div class="w-full h-full pt-8 relative dark:text-gray-100">
                  <div class="py-3 h-full">
                    <div *ngIf="autoLoadData" class="flex flex-col lg:flex-row gap-4">
                      <div class="w-full lg:w-1/2 rounded p-2 dark:bg-gray-600 bg-gray-100">
                        <div class='grid grid-cols-3 gap-4'>
                          <div><h3 class='text-2xl mb-2 text-pink-500'>
                            Staking by
                          </h3>
                          </div>
                          <div>
                            <a href='https://cakedefi.com/?ref=754025' (click)='trackCakeRef()'>
                              <img alt='CakeDefi'
                                   src='assets/img/CakeDeFi_Logo_colored.png'>
                            </a>
                          </div>
                         </div>
                        <br>
                        <div class="flex gap-2">
                          <div class="dark:text-gray-100">
                            Auto APY load from Cake:
                          </div>
                          <div
                            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              id="cakeApyLoadAutoAuto"
                              [(ngModel)]="cakeApyLoadAuto"
                              (ngModelChange)="toggleAutoCake()"
                              [ngClass]="{ 'isChecked': cakeApyLoadAuto }"
                              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                            />
                            <label
                              for="cakeApyLoadAutoAuto"
                              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                          </div>
                        </div>
                        <br>
                        <div class="grid grid-flow-row grid-rows-1 grid-cols-2 gap-3 mb-3">
                          <div>
                            <div
                              class="relative h-14 input-component"
                              [ngClass]="(!dfiInStaking && dfiInStaking !== 0) ? 'empty' : ''"
                              title="DFI"
                            >
                              <input
                                required
                                [(ngModel)]="dfiInStaking"
                                (ngModelChange)="onChangeDfiStaking()"
                                type="number"
                                id="inputDFIStakingAuto"
                                class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                              >
                              <label for="inputDFIStakingAuto"
                                    class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                                DFI
                              </label>
                            </div>
                          </div>
                          <div>
                            <div
                              class="relative h-14 input-component"
                              [ngClass]="(!stakingApy && stakingApy !== 0) ? 'empty' : ''"
                              title="Staking APY"
                            >
                              <input
                                required
                                [(ngModel)]="stakingApy"
                                (ngModelChange)="saveInputStaking()"
                                type="number"
                                id="inputApyStaking"
                                class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                              >
                              <label for="inputApyStaking"
                                    class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                                Staking APY
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                        <div class="flex justify-between mt-4 items-center">
                          <h3 class="text-2xl text-pink-500 border-pink-500">
                            {{ 'address.title' | translate }}
                          </h3>
                          <a
                            class="border border-pink-500 rounded hover:bg-gray-100 text-pink-500 duration-300 ease-in-out transform p-2 cursor-pointer select-none dark:hover:bg-gray-700"
                            (click)="openInfoMenu()"
                          >
                            <span *ngIf="!isInfoOpen">
                              {{ 'address.show-information' | translate }}
                            </span>
                            <span *ngIf="isInfoOpen">
                              {{ 'address.hide-information' | translate }}
                            </span>
                          </a>
                        </div>

                        <div>
                          <div class="transition-all duration-300 ease-in-out transform mt-4 overflow-hidden border bg-gray-200 dark:border-none p-2 rounded dark:bg-gray-700"
                              [ngClass]="{'hidden': !isInfoOpen}">
                            <h3 class="text-xl mb-2 text-pink-500">{{ 'address.title2' | translate }}</h3>
                            <p class="mb-2">
                              <span
                                class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">{{ 'address.imp2' | translate }}</span>
                            </p>
                            <p class="mb-2">
                              {{ 'address.exc0' | translate }}
                            </p>
                            <p class="mb-2">
                              <a class="text-pink-500 hover:underline"
                                (click)="trackHelper()"
                                target="_blank"
                                href="https://github.com/Shelkovenkov/defichain-income-address-helper/releases">
                                https://github.com/Shelkovenkov/defichain-income-address-helper/releases
                              </a>
                            </p>

                            <p class="mb-2">
                              <span
                                class="rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">{{ 'address.imp' | translate }}</span>
                            </p>
                            <p class="mb-2">
                              {{ 'address.exc' | translate }}
                            </p>
                            <p class="mb-2">
                              <span
                                (click)="copyToClipBoard($event.target.innerText)"
                                class="rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-sm px-2 py-1 cursor-pointer">
                                listaccounts {{'{}'}} false false true
                              </span>

                            </p>
                            <p>{{ 'address.exc2' | translate }}</p>
                            <br>
                            <p class="mb-2">
                              <span
                                (click)="copyToClipBoard($event.target.innerText)"
                                class="rounded-full bg-green-500 hover:bg-green-600 transition-all text-white text-sm px-2 py-1 cursor-pointer">listaddressgroupings</span>

                            </p>
                            <p>{{ 'address.exc21' | translate }}</p>
                            <br>
                            <p>{{ 'address.exc3' | translate }}</p>
                          </div>

                          <div class="mt-4">
                            <div class="inline-block w-full md:w-3/4 my-2 md:mt-0">
                              <div
                                class="relative h-14 input-component"
                                [ngClass]="!adress ? 'empty' : ''"
                                title="DFI Address"
                              >
                                <input
                                  required
                                  [(ngModel)]="adress"
                                  id="inputAddress"
                                  class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                                />
                                <label for="inputAddress"
                                      class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                                  DFI Addresses
                                </label>
                              </div>
                            </div>
                            <div class="inline-block w-full md:w-1/4 md:pl-2 align-top">
                              <button
                                class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 rounded-md w-full h-14 text-white focus:outline-none uppercase"
                                (click)="addAdress()"
                              >
                                {{ 'address.button-add' | translate }}
                              </button>
                            </div>
                          </div>
                          <div
                            *ngIf="showDialogAddressesAdded"
                            class="bg-green-500 text-white w-full py-2 px-4 rounded-sm my-2"
                            role="alert"
                          >
                            + {{newAddressesAdded?.length}} Addresses
                            <p *ngFor="let a of newAddressesAdded">{{a}} </p>
                          </div>
                          <div
                            *ngIf="showDialogAddressesNotAdded && adress"
                            class="bg-red-600 text-white w-full py-2 px-4 rounded-sm my-2"
                            role="alert"
                          >
                            {{ 'address.freezer-error' | translate }}
                          </div>
                          <div
                            *ngIf="showDialogAddressesNotAdded && !adress"
                            class="bg-red-600 text-white w-full py-2 px-4 rounded-sm my-2"
                            role="alert"
                          >
                            {{ 'address.empty' | translate }}
                          </div>
                        </div>
                        </div>
                        <br>
                        <div class="flex items-center mb-2">
                          <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              id="masternodeAdress"
                              [(ngModel)]="masternodeAdress"
                              [ngClass]="{ 'isChecked': masternodeAdress }"
                              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                            />
                            <label
                              for="masternodeAdress"
                              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                          </div>
                          <div>
                            Masternode  {{ 'address.label' | translate }}
                          </div>
                        </div>
                        <div *ngIf="masternodeAdress" class="flex flex-row gap-4">
                          <div class="flex items-center">
                            <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                              <input
                                type="checkbox"
                                id="masternodeFreezer5"
                                [(ngModel)]="masternodeFreezer5"
                                [ngClass]="{ 'isChecked': masternodeFreezer5 }"
                                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                              />
                              <label
                                for="masternodeFreezer5"
                                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                              ></label>
                            </div>
                            <div>
                              Freezer 5 Years - 1,5X
                            </div>
                          </div>

                          <div class="flex items-center">
                            <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                              <input
                                type="checkbox"
                                id="masternodeFreezer10"
                                [(ngModel)]="masternodeFreezer10"
                                [ngClass]="{ 'isChecked': masternodeFreezer10 }"
                                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                              />
                              <label
                                for="masternodeFreezer10"
                                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                              ></label>
                            </div>
                            <div>
                              Freezer 10 Years - 2X
                            </div>
                          </div>
                        </div>
                        <br>
                        <h3 class="text-2xl mb-2 text-pink-500">
                          Masternode Monitor Import
                        </h3>
                        <div class="dark:text-gray-100 mb-2">
                          {{ 'mamon-info' | translate }}
                        </div>
                        <div class="flex">
                          <div class="w-3/4">
                            <div
                              class="relative h-14 input-component"
                              [ngClass]="(!mamonKey && mamonKey !== 0) ? 'empty' : ''"
                            >
                              <input
                                required
                                [(ngModel)]="mamonKey"
                                type="text"
                                id="mamon"
                                class="h-full w-full bg-gray-100 px-2 pt-2 transition-all rounded dark:bg-gray-700 dark:text-gray-100 dark:border-none"
                              >
                              <label for="mamon"
                                    class="absolute left-2 transition-all px-1 pointer-events-none dark:text-gray-100">
                                Masternode Monitor Key
                              </label>
                            </div>
                          </div>
                          <div class="inline-block w-1/4 pl-2 align-top">
                            <button
                              type="button"
                              (click)="importMamon()"
                              class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-full h-14 text-white align-top uppercase"
                            >
                              Import
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/2 rounded p-2 dark:bg-gray-600 bg-gray-100">
                        <h3 class="text-2xl mb-2 text-pink-500">
                          {{ 'address.titleList' | translate }}
                        </h3>
                        <div class="inline-block w-1/4 pl-2 align-top">
                          <button
                            type="button"
                            (click)="clearAddresses()"
                            class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-full h-14 text-white align-top uppercase"
                          >
                            Clear Addresses
                          </button>
                        </div>
                        <br>
                        <br>
                        <div *ngIf="allAddresses().length < 1" class="flex-1 h-full w-full text-center items-center justify-center">
                          <span>
                            {{ 'address.noAddresses' | translate }}
                          </span>
                        </div>

                        <div class="flex" *ngIf="allAddresses().length > 0">
                          <div class="block h-auto w-full rounded">
                            <div
                              *ngFor="let a of allAddresses(); index as i;"
                              class="relative lg:px-2 transition duration-150 p-2 dark:hover:bg-gray-600 hover:bg-gray-300"
                              [ngClass]="{'bg-gray-200 dark:bg-gray-800': i % 2 === 1, 'bg-gray-100 dark:bg-gray-700': i % 2 === 0}"
                            >
                              <div class="mr-7 my-2 text-white flex flex-col">
                                <div *ngIf="getAddressMasternode(a)"
                                    class="my-1 font-bold w-fit-content max-w-full overflow-hidden overflow-ellipsis inline-block rounded-full bg-green-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">
                                    Masternode
                                </div>
                                <div *ngIf="getAddressMasternode5(a)"
                                    class="my-1 font-bold w-fit-content max-w-full overflow-hidden overflow-ellipsis inline rounded-full bg-blue-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">
                                    Freezer 5 Years - 1,5X
                                </div>
                                <div *ngIf="getAddressMasternode10(a)"
                                    class="my-1 font-bold w-fit-content max-w-full overflow-hidden overflow-ellipsis inline rounded-full bg-blue-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">
                                    Freezer 10 Years - 2X
                                </div>
                                <div
                                  class="my-1 font-bold w-fit-content max-w-full overflow-hidden overflow-ellipsis min-h-7 inline-block rounded-full bg-pink-500 hover:bg-gray-800 transition-all text-white text-sm px-2 py-1 cursor-pointer">
                                  <a class='hover:underline'
                                     target='_blank'
                                     href='https://defiscan.live/address/{{a}}'>
                                    {{ isIncognitoModeOn ? '****' : getShortOfAddress(a) }}
                                  </a>
                                </div>
                              </div>

                              <a
                                class="absolute top-1/2 right-3 transform -translate-y-1/2 hover:text-pink-500 cursor-pointer"
                                (click)="deleteAdress(a)"
                              >
                                <div class="w-3 h-3 flex items-center hover:text-pink-500">
                                  <app-icon name="close"></app-icon>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault>
              <h1 class="text-3xl mb-4 text-pink-500">Dashboard</h1>

              <div class="grid grid-flow-row grid-cols-1 auto-rows-max lg:grid-cols-2 lg:gap-4 gap-6">
                <div *ngIf="wallet && poolBtc">
                  <app-income
                    [isIncognitoModeOn]="isIncognitoModeOn"
                    [showOnlyGraph]="true"
                    [poolBtc]="poolBtc"
                    [fiat]="fiat"
                    [stakingOut]="stakingOut"
                    [masternodeOut]="poolMasternodeOut"
                    [dfiInStaking]="dfiInStaking"
                    [dfiInLockStaking]='dfiInLockStaking'
                    [stakingLockApy]='stakingLockStats?.apy'
                    [stakingApy]="stakingApy"
                    [masternodesApr]="stakingApyMN"
                    [masternodesCount]="adressesMasternodes?.length"
                    [dfiInMasternodes]="dfiInMasternodes"
                    [getDfiCountInLM]="getDfiCountInLM()"
                    [getLMUsd]="getLMUsd()"
                    [poolAllOut]='poolAllOut'
                    [income]='income'
                    [lmOut]='lmOut'
                  ></app-income>
                </div>
                <div *ngIf="wallet && poolBtc && pools && adressBalances && dataLoaded">
                  <app-value
                    [isIncognitoModeOn]="isIncognitoModeOn"
                    [hideHoldings]="true"
                    [pools]='pools'
                    [fiat]="fiat"
                    [dfiInStaking]="dfiInStaking"
                    [dfiInLockStaking]='dfiInLockStaking'
                    [dfiInMasternodes]="dfiInMasternodes"
                    [adressBalances]="adressBalances"
                    [freezer5]="adressesMasternodesFreezer5"
                    [freezer10]="adressesMasternodesFreezer10"
                    [vaultsOfAllAddresses]='vaultsOfAllAddresses'
                    [cexPrice]='priceDFICEX'
                    [rewards]='rewards'
                    [autoLoadData] = 'autoLoadData'
                    [currentPage]='currentPage'
                    [income]='income'
                  ></app-value>
                </div>
                <div class="lg:col-span-2 overflow-x-auto">
                  <app-dex-statistics *ngIf='cryptoPools && stocksPools'
                    [fiat]="fiat"
                    [oceanStats]="oceanStats"
                    [stocksPools]='stocksPools'
                    [cryptoPools]='cryptoPools'
                    [stakingApyCake]="stakingApyCake"
                    [stakingApyMN]="stakingApyMN"
                    [MNCount]="masternodeCount"
                    [MNCount0Freezer]="masternodeCount0Freezer"
                    [MNCount5Freezer]="masternodeCount5Freezer"
                    [MNCount10Freezer]="masternodeCount10Freezer"
                    [burnedDfi]="dfiBurned"
                    [rewards]="rewards"
                    [blockTimeUsed]="blocktimeInS"
                    [blockTimeSecond]="blocktimeInSSecond"
                    [isIncognitoModeOn]="isIncognitoModeOn"
                    [correlationDays]='correlationDays'
                    [priceDFICEX]='priceDFICEX'
                    [poolPairsOcean]='poolPairsOcean'
                    [lockStaking]='stakingLockStats'
                    (callDefiView)="callDefiView($event)"
                  >
                  </app-dex-statistics>
                </div>
              </div>
            </div>
          </container-element>
        </div>
      </div>
    </div>
  </div>
</div>

