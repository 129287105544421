<div>
   <h1 class="text-3xl mb-4 text-pink-500">{{ "income.calc" | translate }}</h1>
  <div class="w-full lg:w-1/2">
    <div class="grid grid-flow-col grid-cols-3 select-none">
      <a
        (click)="onChangeDetails('Staking')"
        class="text-center cursor-pointer border-b-2 border-transparent uppercase pb-2 self-center"
        [ngClass]="details === 'Staking' ? 'text-pink-500 border-pink-500': 'dark:text-gray-100'"
      >
        Staking
      </a>
      <a
        (click)="onChangeDetails('LM')"
        class="text-center cursor-pointer border-b-2 border-transparent uppercase pb-2 self-center"
        [ngClass]="details === 'LM' ? 'text-pink-500 border-pink-500': 'dark:text-gray-100'"

      >
        Liquidity Mining
      </a>
      <a
        (click)="onChangeDetails('Calc')"
        class="text-center cursor-pointer border-b-2 border-transparent uppercase pb-2 self-center"
        [ngClass]="details === 'Calc' ? 'text-pink-500 border-pink-500': 'dark:text-gray-100'"
      >
        {{ "details-calc" | translate }}
      </a>
    </div>
  </div>

  <div class="mt-4">
    <div *ngIf="details === 'Calc'">
      <app-apy-calculator
        [stakingApyMN]="stakingApyMN"
        [stakingApyCake]="stakingApyCake"
        [poolBtc]="poolBtc"
        [fiat]="fiat"
      ></app-apy-calculator>
    </div>
    <div *ngIf="details === 'Staking'">
      <app-staking-target-calculator [stakingApy]="stakingApy"></app-staking-target-calculator>
    </div>
    <div *ngIf="details === 'LM'">
      <app-lm-target-calculator
      [pools]='pools'
      [poolBtc]='poolBtc'
      >
      </app-lm-target-calculator>
    </div>
  </div>
</div>
