<div class='container'>
  <ngx-spinner
    bdColor='rgba(51,51,51,0.8)'
    size='large'
    color='#FF00AF'
    type='pacman'
    fullScreen=false
    name='historySpinner'
  >
    <p style='font-size: 20px; color: white'>Loading History ...</p>
  </ngx-spinner>
</div>

<div class="flex">

  <div class="inline-block pl-2 align-top">
    <select
      class='bg-gray-100 w-35 h-14 px-3 py-2 rounded-md mr-2 dark:bg-gray-700 dark:text-gray-100 mb-2'
      (change)='onChangeStockPool($event.target.value)'
    >
      <option selected>BTC</option>
      <option>ETH</option>
      <option>LTC</option>
      <option>USDT</option>
      <option>USDC</option>
      <option>USDT-DUSD</option>
      <option>USDC-DUSD</option>
      <option>DOGE</option>
      <option>BCH</option>
      <option>DUSD</option>
      <option>TSLA</option>
      <option>SPY</option>
      <option>QQQ</option>
      <option>PLTR</option>
      <option>SLV</option>
      <option>AAPL</option>
      <option>GLD</option>
      <option>GME</option>
      <option>GOOGL</option>
      <option>ARKK</option>
      <option>BABA</option>
      <option>VNQ</option>
      <option>URTH</option>
      <option>TLT</option>
      <option>PDBC</option>
      <option>AMZN</option>
      <option>NVDA</option>
      <option>COIN</option>
      <option>EEM</option>
      <option>MSFT</option>
      <option>NFLX</option>
      <option>FB</option>
      <option>VOO</option>
      <option>DIS</option>
      <option>MCHI</option>
      <option>MSTR</option>
      <option>INTC</option>
      <option>BRK.B</option>
      <option>PYPL</option>
      <option>KO</option>
      <option>PG</option>
      <option>SAP</option>
      <option>URA</option>
      <option>CS</option>
      <option>GSG</option>
      <option>PPLT</option>
      <option>GOVT</option>
      <option>TAN</option>
      <option>XOM</option>
      <option>JNJ</option>
      <option>ADDYY</option>
      <option>GS</option>
      <option>DAX</option>
      <option>WMT</option>
      <option>UL</option>
      <option>UNG</option>
      <option>USO</option>
      <option>XLRE</option>
      <option>XLE</option>
      <option>ARKX</option>
      <option>VBK</option>
    </select>
  </div>
  <div class="inline-block pl-2 align-top">
    <select
      class='bg-gray-100 w-33 h-14 px-3 py-2 rounded-md mr-2 dark:bg-gray-700 dark:text-gray-100 mb-2'
      (change)='onChangeDateStockPool($event.target.value)'
    >
      <option selected>1D</option>
      <option>7D</option>
      <option>1M</option>
      <option>3M</option>
      <option>1Y</option>
      <option>ALL</option>
    </select>
  </div>
  <div class="w-40 pl-2 align-top">
    <button
      type="button"
      (click)="loadAll()"
      class="bg-pink-500 hover:bg-pink-600 transform transition-all delay-150 focus:outline-none rounded-md w-full h-14 text-white align-top uppercase"
    >
      GO
    </button>
  </div>
</div>
<br>

<div class='mt-2'>
  <apx-chart
    *ngIf='history && history.length > 0 && chartOptions && chartOptions.series.length > 0'
    [series]='chartOptions?.series'
    [chart]='chartOptions?.chart'
    [xaxis]= 'chartOptions?.xaxis'
    [stroke]='chartOptions?.stroke'
    [markers]='chartOptions?.markers'
    [tooltip]='chartOptions?.tooltip'
    [yaxis]='chartOptions?.yaxis'
    [grid]='chartOptions?.grid'
    [legend]='chartOptions?.legend'
    [title]='chartOptions?.title'
    [theme]='chartOptions?.theme'
  ></apx-chart>
</div>
<div class='mt-2'>
  <apx-chart
    *ngIf='history && history.length > 0 && chartOptions3 && chartOptions3.series.length > 0'
    [series]='chartOptions3?.series'
    [chart]='chartOptions3?.chart'
    [xaxis]= 'chartOptions3?.xaxis'
    [stroke]='chartOptions3?.stroke'
    [markers]='chartOptions3?.markers'
    [tooltip]='chartOptions3?.tooltip'
    [yaxis]='chartOptions3?.yaxis'
    [grid]='chartOptions3?.grid'
    [legend]='chartOptions3?.legend'
    [title]='chartOptions3?.title'
    [theme]='chartOptions3?.theme'
  ></apx-chart>
</div>
<div class='mt-2'>
  <apx-chart
    *ngIf='history && history.length > 0 && chartOptions2 && chartOptions2.series.length > 0'
    [series]='chartOptions2?.series'
    [chart]='chartOptions2?.chart'
    [xaxis]= 'chartOptions2?.xaxis'
    [stroke]='chartOptions2?.stroke'
    [markers]='chartOptions2?.markers'
    [tooltip]='chartOptions2?.tooltip'
    [yaxis]='chartOptions2?.yaxis'
    [grid]='chartOptions2?.grid'
    [legend]='chartOptions2?.legend'
    [title]='chartOptions2?.title'
    [theme]='chartOptions2?.theme'
  ></apx-chart>
</div>
<div class='mt-2'>
  <apx-chart
    *ngIf='history && history.length > 0 && chartOptions4 && chartOptions4.series.length > 0'
    [series]='chartOptions4?.series'
    [chart]='chartOptions4?.chart'
    [xaxis]= 'chartOptions4?.xaxis'
    [stroke]='chartOptions4?.stroke'
    [markers]='chartOptions4?.markers'
    [tooltip]='chartOptions4?.tooltip'
    [yaxis]='chartOptions4?.yaxis'
    [grid]='chartOptions4?.grid'
    [legend]='chartOptions4?.legend'
    [title]='chartOptions4?.title'
    [theme]='chartOptions4?.theme'
  ></apx-chart>
</div>
